import React from "react";
import { connect } from "react-redux";
import "./index.sass";
import { styled, createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from "@mui/material";
const theme = createTheme();

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    textTransform: "capitalize",
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: "2rem",
    padding: "0.5rem 2rem",
    width: "100%",
  },
  table: {
    maxWidth: "100%",
    margin: "auto",
  },
  tableContainer: {
    marginTop: "2rem",
    position: "sticky",
    top: "200px",
  },
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#07639d",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface Props {
  getUserList: any;
  userList: any;

}

const AdminHomePageTable: React.FC<Props> = ({
  getUserList,
  userList,
}) => {
  const classes = useStyles();
  return (
    <div style={{ width: "100%" }} className="data-table">
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "350px", marginTop: "2rem", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <h4>USER LIST</h4>
        <Table stickyHeader aria-label="simple table" >
          <TableHead>

            <StyledTableRow>
              <StyledTableCell align="center">S.NO</StyledTableCell>
              <StyledTableCell align="center">USER NAME</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              {/* <StyledTableCell align="center">Email</StyledTableCell> */}
              <StyledTableCell align="center">Usergroup</StyledTableCell>
              <StyledTableCell align="center">Is Active</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {userList && userList.length > 0 && userList.map((data: any, index: any) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell align="center">{data?.username}</StyledTableCell>
                  <StyledTableCell align="center">{data?.fullname}</StyledTableCell>
                  <StyledTableCell align="center">{data?.usergroup}</StyledTableCell>
                  <StyledTableCell align="center">{data?.is_active ? "Active" : "Deactive"}</StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
})(AdminHomePageTable);
