import * as React from 'react';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PostEmailAuthentication } from '../../action/branchServiceAction'
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import InputAdornment from '@mui/material/InputAdornment';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import StepButton from '@mui/material/StepButton';
import EmailPdfPage from './emailPdf'
import { ACTIONS } from '../../interfaces/branchServiceType'
import PolicyBasicHeade from '../../../HOC/policyBasicHeade';
import { useEffect } from 'react';
const steps = ['Validate Email', 'Email Details'];
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "80%",
        flexGrow: 21,
    },
    grid: {
        backgroundColor: "grey",
        height: "50vh",
        textAlign: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#2196f3"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    paper: {
        marginTop: theme.spacing(5),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
})
interface Props {
    PostEmailAuthentication: any
    EmailDetailsData: any;
    EmailDetailsLoading: any;

}

const EmailValidationForm: React.FC<Props> = ({
    PostEmailAuthentication,
    EmailDetailsData,
    EmailDetailsLoading
}) => {
    const classes = useStyles();
    // const theme = createTheme();
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const [email, setEmail] = React.useState<any>("")
    const [individualName, setIndividualName] = React.useState<any>("")
    const [organizationName, setOrganizationName] = React.useState<any>("")
    const [consent, setConsent] = React.useState<boolean>(true)
    const [activeStep, setActiveStep] = React.useState(0);
    const [applicationNumber, setApplicationNumber] = React.useState<any>("")
    const [customerName, setCustomerName] = React.useState<any>("")
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // const handleStep = (step: number) => () => {
    //     setActiveStep(step);
    // };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setEmail("")
        setIndividualName("")
        setOrganizationName("")
        window.location.reload();
        dispatch({ type: ACTIONS.POST_MOBILE_DETAILS_DATA, payload: [] });
        setActiveStep(0);
        setCompleted({});
    };
    // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     console.log({
    //         email: data.get('email'),
    //         password: data.get('password'),
    //     });
    // };
    const handleEmailValidation = async (e: any) => {
        e.preventDefault();
        const body = {
            email,
            individualName,
            organizationName,
            consent: consent ? "Y" : "N"
        }
        PostEmailAuthentication(body)
    }
    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setConsent(event.target.checked)
    // };

    React.useEffect(() => {
        if (EmailDetailsData && EmailDetailsData?.result && EmailDetailsData["status-code"] === "101") {
            handleComplete()
        }
    }, [EmailDetailsData])

    useEffect(() => {
        if (localStorage.getItem('policynumber') !== null) {
            setApplicationNumber(localStorage.getItem('policynumber'))
        }
        else {
            setApplicationNumber("")
        }
        if (localStorage.getItem('ocrname') !== null) {
            setCustomerName(localStorage.getItem('ocrname'))
        }
        else {
            setCustomerName("")
        }
    }, [])
    return (
        <>
            <Container component="main" maxWidth="xs" style={{ maxWidth: "100%" }}>
                <CssBaseline />
                <div className={classes.toolbar} />
                <Box sx={{ width: '100%' }}>
                    <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepButton color="inherit" >
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </Box>
                <PolicyBasicHeade applicationNumber={applicationNumber} setApplicationNumber={setApplicationNumber} setCustomerName={setCustomerName} customerName={customerName} />
                {Object.keys(completed).length === 0 || EmailDetailsLoading ? (
                    <Container component="main" maxWidth="xs">
                        <div className={classes.paper}>
                            <Grid
                                container
                                style={{ backgroundColor: "white" }}
                                className={classes.grid}
                                alignItems="center"
                                spacing={3}
                            >
                                <Grid item container>
                                    <Grid item container alignItems="center" direction="column">
                                        <Grid item>
                                            <Avatar className={classes.avatar} sx={{ background: "#002984" }}>
                                                <MailOutlineRoundedIcon />
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            <Typography component="h1" variant="h5">
                                                Email Authentication
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignItems="center"
                                    direction="column"
                                >
                                    <Grid item spacing={3} >
                                        <form onSubmit={handleEmailValidation}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="email"
                                                        label="Email Address"
                                                        name="email"
                                                        autoComplete="email"
                                                        value={email}
                                                        onChange={(e: any) => setEmail(e.target.value)}
                                                        InputLabelProps={{
                                                            style: { color: '#0a0a0a',fontWeight:"bold"},
                                                          }}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="start">
                                                                <Tooltip title="Given email id in the format example@emample.com" placement="top-end">
                                                                    <InfoIcon />
                                                                </Tooltip>
                                                            </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        autoComplete="given-name"
                                                        name="individualName"
                                                        required
                                                        fullWidth
                                                        id="individualName"
                                                        label="Individual Name"
                                                        autoFocus
                                                        value={individualName}
                                                        onChange={(e: any) => setIndividualName(e.target.value)}
                                                        InputLabelProps={{
                                                            style: { color: '#0a0a0a',fontWeight:"bold"},
                                                          }}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="start">
                                                                <Tooltip title="Individual Full Name">
                                                                    <InfoIcon />
                                                                </Tooltip>
                                                            </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        autoComplete="given-name"
                                                        required
                                                        fullWidth
                                                        id="organizationName"
                                                        label="Organization Name"
                                                        name="organizationName"
                                                        value={organizationName}
                                                        autoFocus
                                                        onChange={(e: any) => setOrganizationName(e.target.value)}
                                                        InputLabelProps={{
                                                            style: { color: '#0a0a0a',fontWeight:"bold"},
                                                          }}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="start">
                                                                <Tooltip title="Organization name where individual belong">
                                                                    <InfoIcon />
                                                                </Tooltip>
                                                            </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        endIcon={<SendIcon />}
                                                        disabled={EmailDetailsLoading}
                                                        style={{ background: "#07639d" }}
                                                        type="submit"
                                                    >
                                                        Send
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} md={12} sm={12}>
                                                    {EmailDetailsLoading ? (
                                                        <LinearProgress color="secondary" />
                                                    ) : ""}
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                ) : <EmailPdfPage EmailDetailsData={EmailDetailsData} customerName={customerName} applicationNumber={applicationNumber} />}

            </Container>
        </>
    )
}
const mapStateToProps = (state: any) => ({
    EmailDetailsData: state.BranchServiceReducer.EmailDetailsData,
    EmailDetailsLoading: state.BranchServiceReducer.EmailDetailsLoading
})
export default connect(mapStateToProps, {
    PostEmailAuthentication
})(EmailValidationForm)
