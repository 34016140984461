import * as React from 'react';
import { connect } from "react-redux";
import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import MobilePDFPage from './mobilePdf';
import LinearProgress from '@mui/material/LinearProgress';
import { createTheme } from '@mui/material/styles';
import { Avatar, Grid, Typography } from '@mui/material';
import DetailsIcon from '@mui/icons-material/Details';

const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: "0.4rem",
    },
    content: {
        width: "100%",
        flexGrow: 21,
    },
    paper: {
        padding: "20px 20px 20px 20px",
        marginTop: "20px",
        width: "100%",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#2196f3"
    },

})
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface Props {
    validateReqData: any;
    PostMobileDetails: any;
    MobileDetailsData: any;
    mobileDetailsLoading: any;
    mobileNumber: any;
    // handleComplete:any;
    otpRequestData: any;
    customerName: any;
    // applicationNumber:any
    policyNumber: any;
}
const ViewDetailsPage: React.FC<Props> = ({
    validateReqData,
    PostMobileDetails,
    MobileDetailsData,
    mobileDetailsLoading,
    mobileNumber,
    // handleComplete,
    otpRequestData,
    customerName,
    // applicationNumber,
    policyNumber,
}) => {
    const classes = useStyles();
    const [show, setShow] = React.useState<boolean>(false)

    const handleShowDetails = () => {
        const body =
        {
            "request_id": otpRequestData?.request_id
        }
        setShow(true)
        PostMobileDetails(body)
    }
    React.useEffect(() => {
        if (mobileDetailsLoading && mobileDetailsLoading['status-code'] === "101") {
            // handleComplete()
        }
    }, [mobileDetailsLoading])

    console.log(validateReqData, "validateReqData")
    return (
        <div>
            <TableContainer component={Paper} style={{ margin: "2rem auto" }} >
            <Grid item container alignItems="center" direction="column">
                            <Grid item>
                                <Avatar className={classes.avatar} sx={{ background: "#002984" }}>
                                    <DetailsIcon />
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography component="h1" variant="h5">
                                    Mobile Details
                                </Typography>
                            </Grid>
                        </Grid>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">OTP Valid</StyledTableCell>
                            <StyledTableCell align="center">Provider</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {validateReqData && validateReqData?.result&&validateReqData?.result?.sim_details?(
                        <TableBody>
                            <StyledTableRow key="provider">
                                <StyledTableCell align="center" component="th" scope="row">{validateReqData?.result?.sim_details?.otp_validated ? "YES" : "NO"}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">{validateReqData?.result?.sim_details?.provider}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">
                                    <Button
                                        variant="contained"
                                        style={{background:"#07639d"}}
                                        onClick={handleShowDetails}
                                    >
                                        View Details
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    ) : ""}

                </Table>
            </TableContainer>
            {mobileDetailsLoading ? (<LinearProgress />)
                : ""}
            {MobileDetailsData && MobileDetailsData['status-code'] === "101" ? (
                <MobilePDFPage MobileDetailsData={MobileDetailsData} mobileNumber={mobileNumber} customerName={customerName}
                    applicationNumber={policyNumber} />) : ""}

        </div>
    )
}
const mapStateToProps = (state: any) => ({

})
export default connect(mapStateToProps, {

})(ViewDetailsPage)

