import Auth from '../services/Auth';
import SecureStorage from '../config/SecureStorage'
import snackBarUpdate from '../actions/snackBarActions';
import { ACTIONS } from '../interfaces/actionTypes/loginTypes';
import AXIOS from '../config/Axios';
import Prefix from '../config/ApiPrefix';
import AXIOS1 from '../config/Axios1';

export const login = (body: object) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true })
  try {
    const {
      data,
      status
    } = await Auth.login(body );
    let authResponse: any = [];
    if (status === 201||status === 200 ) {
      authResponse = {
        data,
        status
      };
      const user = data.username;
      const token = data.access_token;
      const fullname = data.fullname;
      const user_group = data.user_group;
      const passwordStatus=data?.password_change
      SecureStorage.setItem('token', token);
      SecureStorage.setItem('fullname', fullname);
      SecureStorage.setItem('USER_NAME', user);
      SecureStorage.setItem('USER_GROUP', user_group);
      dispatch({type: ACTIONS.GET_USER, payload: passwordStatus })
      dispatch({ type: ACTIONS.SET_USER, payload: user })
      // dispatch({type: ACTIONS.GET_USER_PROFILE, payload: data })
      dispatch({ type: ACTIONS.SET_LOADING, payload: false })
    }
    return authResponse;
  }
  catch (err:any) {
    let title = ''
    if (err) {
      dispatch({ type: ACTIONS.SET_LOADING, payload: false })
      const { data} = err?.response
      if (data?.statusCode===401||data?.statusCode===400) {
        title = data?.message
      }
    }
    snackBarUpdate({
      payload: {
        message: title || "Some unexpected error occurred please refresh the page",
        status: true,
        type: 'error',
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false })
    throw err;
  }
};

export const getFirebaseNotification = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(`${Prefix.api}/notification/token_save/`,
            body,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_FIREBASE_NOTIFICATION, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Some unexpected error occurred please refresh the page!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Some unexpected error occurred please refresh the page!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const resetPasswordReset = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.RESET_PASSWORD, payload: false });
  dispatch({ type: ACTIONS.SEND_OTP, payload: false });
}

export const resetPassword = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/auth/password_reset/confirm/`,
      body,
    );
    dispatch({ type: ACTIONS.RESET_PASSWORD, payload: data });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err:any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Some unexpected error occurred please refresh the page!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Some unexpected error occurred please refresh the page!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getUserDetails = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(`${Prefix.api}/auth/shortprofile/`, {
            headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        });
        dispatch({ type: ACTIONS.GET_USER_DETAILS, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Some unexpected error occurred please refresh the page!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Some unexpected error occurred please refresh the page!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.GET_USER_DETAILS, payload: [] });
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const changeUserPassword = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.post(`${Prefix.api}/users/changePasword`, body);
    dispatch({ type: ACTIONS.CHANGE_PASSWORD_REQUEST, payload: data });
    snackBarUpdate({
      payload: {
        message: "Password Change Successfully!",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err:any) {
    let title = "";
    if (err.response) {
      title = err.response.data.message;
    } else {
      title = err.message;
    }
    snackBarUpdate({
      payload: {
        message: title || "Some unexpected error occurred please refresh the page!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const panelUserLogout = () => async (dispatch: Function) => {

  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/auth/users/paneluser/logout/`,
    {},
    { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.PANELUSER_LOGOUT, payload: data });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err:any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Some unexpected error occurred please refresh the page!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Some unexpected error occurred please refresh the page!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const sendOtp = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/auth/password_reset/`,
      body,
    );
    dispatch({ type: ACTIONS.SEND_OTP, payload: data });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err:any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Some unexpected error occurred please refresh the page!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Some unexpected error occurred please refresh the page!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const logout = () => ({ type: ACTIONS.LOGOUT })

export const checkUser = () => async (dispatch: Function) => {

  try {
    const data = await Auth.checkLogin();
    let checkUserLoginResponse;
    if (Object.keys(data.user).length > 0) {
      checkUserLoginResponse = data;
      dispatch({ type: ACTIONS.SET_USER, payload: data });
    }
    return checkUserLoginResponse;
  } catch (error) {
    return error;
  }
};

export const setProfile = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await Auth.setProfile();
    dispatch({ type: ACTIONS.SET_PROFILE, payload: data });
  } catch (error) {
  }
};

export const getEkycAccessToken = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const body=new URLSearchParams({
        'userId': 'PliBranchApp',
        'password': 'Abc#121',
    })
      const { data } = await AXIOS1.post(`EkycService/ekyc/otp/getToken`,
          body,
      );
      snackBarUpdate({
        payload: {
            message: "User Created Successfully!",
            status: true,
            type: "success",
        },
    })(dispatch);

      dispatch({ type: ACTIONS.GET_EKYC_ACCESSTOKEN, payload: data });
  } catch (err: any) {
      let title = "";
      if (err.response) {
          title = String(err.response.data.message);
      } else {
          title = "Something went wrong!";
      }
      snackBarUpdate({
          payload: {
              message: title || "Something went wrong!",
              status: true,
              type: "error",
          },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
  }
};


// export const refreshToken = () => async (dispatch: Function) => {
//   dispatch({ type: ACTIONS.SET_LOADING, payload: true })
//   const rtoken = SecureStorage.getItem('refreshToken');
//   try {
//     const {
//       data,
//       status
//     } = await Auth.findAccessToken({ refresh: rtoken });
//     let authResponse: any = [];
//     if (status === 200 || status === 201) {
//       authResponse = {
//         data,
//         status
//       };
//       const accessToken = data.access;
//       SecureStorage.setItem('accessToken', accessToken);
//       return authResponse;
//     }else if (status === 401) {
//       return "logout";
//     }else{
//       return "logout";
//     }
//   } catch (err:any) {
//     return "logout";
//   }
// };