import React, { useEffect } from "react";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import "./index.sass";
import RegistrationForm from './registeruser'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { createTheme, Grid, Paper } from "@mui/material";

const theme = createTheme();
const useStyles = makeStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      textTransform: "capitalize",
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#07639d",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  

interface Props {
    getUserList:any;
    userList:any;
    updateUserProfile:any;

}

const AdminHomePageDeActivateUserTable: React.FC<Props> = ({
    getUserList,
    userList,
    updateUserProfile
}) => {
  const classes = useStyles();
  const filterData=userList&&userList.length>0&&userList.filter((data:any)=>data.is_active===false)

  const handleActivate = async(e:any,id:any) =>{
    e.preventDefault()
    const body={
        is_active:true
    }
  await  updateUserProfile(body,id)
  getUserList()

  }

  return (
    <div style={{ width: "100%" }} className="data-table">
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "350px",marginTop:"0rem" ,boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}
      >
              <h4>DEACTIVE USER LIST</h4>
          <Table stickyHeader aria-label="simple table" style={{marginTop:"inherit"}}>
            <TableHead>
          
              <StyledTableRow>
                <StyledTableCell align="center">S.NO</StyledTableCell>
                <StyledTableCell align="center">USER NAME</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                {/* <StyledTableCell align="center">Email</StyledTableCell> */}
                <StyledTableCell align="center">Usergroup</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
                {filterData&&filterData.length>0&&filterData.map((data:any,index:any)=>{
                    return(
                        <StyledTableRow key={index}>
                              <StyledTableCell align="center">{index+1}</StyledTableCell>
                              <StyledTableCell align="center">{data?.username}</StyledTableCell>
                              <StyledTableCell align="center">{data?.fullname}</StyledTableCell>
                              {/* <StyledTableCell align="center">{data?.email}</StyledTableCell> */}
                              <StyledTableCell align="center">{data?.usergroup}</StyledTableCell>
                              <StyledTableCell align="center"><CheckCircleIcon onClick={(e:any)=>handleActivate(e,data._id)}/></StyledTableCell>
                        </StyledTableRow>
                    )
                })}
            </TableBody>
          </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
})(AdminHomePageDeActivateUserTable);
