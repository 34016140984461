import * as React from 'react';
import { useTheme, createTheme } from '@mui/material/styles';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import StepButton from '@mui/material/StepButton';
import { GetMobileOtp, validateOtpInput, PostMobileDetails } from '../../action/branchServiceAction'
import ValidateOtpPage from './validateOtpPage'
import { ACTIONS } from '../../interfaces/branchServiceType'
import MobileValidationInput from './mobileInputPage'
import LinearProgress from '@mui/material/LinearProgress';
import ViewDetailsPage from './viewDetails'
import { useEffect, useState } from 'react';
import PolicyBasicHeade from '../../../HOC/policyBasicHeade';
const steps = ['Get Otp', 'Validate Otp', 'Get Mobile Details'];
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "100%",
        flexGrow: 21,
    },
})
interface Props {
    GetMobileOtp: any;
    sendOtploading: boolean;
    otpRequestData: any;
    validateOtpInput: any;
    validateReqData: any;
    otpValidateLoding: boolean;
    PostMobileDetails: any;
    MobileDetailsData: any;
    mobileDetailsLoading: any;
}
const MobilevalidationPage: React.FC<Props> = ({
    GetMobileOtp,
    sendOtploading,
    otpRequestData,
    validateOtpInput,
    validateReqData,
    otpValidateLoding,
    PostMobileDetails,
    MobileDetailsData,
    mobileDetailsLoading
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch()
    const [mobileNumber, setMobileNumber] = React.useState<any>("")
    const [mobileValidation, setMobileValidation] = React.useState<boolean>(true);
    const [otp, setOtp] = React.useState<any>("")
    const [consent, setConsent] = React.useState<boolean>(true)
    const [pageName, setPageName] = React.useState("send")
    const [maxLimitError, setMaxLimitError] = React.useState("")
    const [activeStep, setActiveStep] = React.useState(0);
    const [applicationNumber,setApplicationNumber]=useState<any>("")
    const [customerName,setCustomerName]=useState<any>("")
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // const handleStep = (step: number) => () => {
    //     setActiveStep(step);
    // };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        dispatch({ type: ACTIONS.GET_MOBILE_OTP, payload: [] });
        dispatch({ type: ACTIONS.POST_VALIDATE_OTP_INPUT, payload: [] });
        dispatch({ type: ACTIONS.POST_MOBILE_DETAILS_DATA, payload: [] });
        setActiveStep(0);
        setCompleted({});
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConsent(event.target.checked)
    };

    const handleGetOtp = async (e: any) => {
        e.preventDefault();
        const data = {
            mobile: mobileNumber,
            consent: consent ? "Y" : "N"
        }
        GetMobileOtp(data)
    }
    const handleValidateOtp = async (e: any) => {
        e.preventDefault();
        const body = {
            otp,
            request_id: otpRequestData?.request_id
        }
        await validateOtpInput(body)
        dispatch({ type: ACTIONS.POST_MOBILE_DETAILS_DATA, payload: [] });

    }
    useEffect(() => {
        if (otpRequestData && otpRequestData?.status === "104") {
            setMaxLimitError("Max Limit Exceeded")
        }
        else {
            if (otpRequestData && otpRequestData?.result && otpRequestData?.result?.message === "Otp has been sent to your mobile number") {
                handleComplete()
            }
            setMaxLimitError("")
        }

    }, [otpRequestData])
    useEffect(() => {
        if (validateReqData && validateReqData?.result && validateReqData?.result?.sim_details?.otp_validated) {
            handleComplete()
        }
    }, [validateReqData])

    

    useEffect(()=>{
        if(localStorage.getItem('policynumber')!==null){
            setApplicationNumber(localStorage.getItem('policynumber'))
        }
        else{
            setApplicationNumber("")
        }
        if(localStorage.getItem('ocrname')!==null){
            setCustomerName(localStorage.getItem('ocrname'))
        }
        else{
            setCustomerName("")
        }
    },[])
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Box sx={{ width: '100%' }}>
                    <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepButton color="inherit" >
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                    <PolicyBasicHeade customerName={customerName} setCustomerName={setCustomerName} setApplicationNumber={setApplicationNumber} applicationNumber={applicationNumber}/>
                    <div>
                        {Object.keys(completed).length === 0 || sendOtploading ? (
                            <> 
                            <p style={{ color: "red", fontWeight: "bold" }}>{maxLimitError}</p>
                            {sendOtploading ? (
                                <LinearProgress color="secondary" />
                            ) : ""}
                                <MobileValidationInput
                                    mobileNumber={mobileNumber}
                                    setMobileNumber={setMobileNumber}
                                    mobileValidation={mobileValidation}
                                    setMobileValidation={setMobileValidation}
                                    sendOtploading={sendOtploading}
                                    handleGetOtp={handleGetOtp}
                                    consent={consent}
                                    handleChange={handleChange}
                                    customerName={customerName}
                                    applicationNumber={applicationNumber}
                                />
                               
                            </>
                        ) : Object.keys(completed).length === 1 ? (
                            <>
                             {otpValidateLoding ? (
                                    <LinearProgress color="secondary" />
                                ) : ""}
                                <ValidateOtpPage
                                    otp={otp}
                                    setOtp={setOtp}
                                    validateOtpInput={validateOtpInput}
                                    handleValidateOtp={handleValidateOtp}
                                />
                               
                            </>

                        ) : <ViewDetailsPage
                            validateReqData={validateReqData}
                            otpRequestData={otpRequestData}
                            PostMobileDetails={PostMobileDetails}
                            MobileDetailsData={MobileDetailsData}
                            mobileDetailsLoading={mobileDetailsLoading}
                            mobileNumber={mobileNumber}
                            handleComplete={handleComplete}
                            customerName={customerName}
                            applicationNumber={applicationNumber}
                        />
                        }

                    </div>
                </Box>
            </main>
        </>
    );
};
const mapStateToProps = (state: any) => ({
    sendOtploading: state.BranchServiceReducer.sendOtploading,
    otpRequestData: state.BranchServiceReducer.otpRequestData,
    validateReqData: state.BranchServiceReducer.validateReqData,
    otpValidateLoding: state.BranchServiceReducer.otpValidateLoding,
    MobileDetailsData: state.BranchServiceReducer.MobileDetailsData,
    mobileDetailsLoading: state.BranchServiceReducer.mobileDetailsLoading,
});

export default connect(mapStateToProps, {
    GetMobileOtp,
    validateOtpInput,
    PostMobileDetails
})(MobilevalidationPage);