type ApiData = {
    api?: string;
    api2?:string
}

const Prefix: ApiData = {
    api: 'v2',
    api2:'v3',
};

export default Prefix;