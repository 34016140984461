export const arr = [
  {
    role: "Admin",
    url: "admin",
  },
  {
    role: "Branch",
    url: "branch",
  },
  {
    role: "abcde",
    url: "abcde",
  },
];
