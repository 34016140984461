import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { getUserList } from '../../action/adminAction'
import { createTheme, Grid, MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "./index.sass";
const theme = createTheme();
const useStyles = makeStyles({
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        textTransform: "capitalize",
    },
    labelText: {
        border: "1px solid",
        color: "red",

        '& label': {
            fontSize: "18px !important",
        }
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "100%",
    },
    table: {
        maxWidth: "100%",
        margin: "auto",
    },
    tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        height: "100%",
        // display: "flex",
        top: "5%",
        flexDirection: "column",
        justifyContent: "space-around",
    },
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


interface Props {
    createNewUser: any;
    getUserList: any;
}

const RegisterUserForm: React.FC<Props> = ({
    createNewUser,
    getUserList,
}) => {
    const { register, reset, handleSubmit, watch, formState: { errors } } = useForm();
    const classes = useStyles();
    const [fullName, setFullName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [userName, setUserName] = useState<string>("")
    const [userGroup, setUserGroup] = useState<string>("")
    const [isActive, setIsActive] = useState<string>("false")
    const [passwordChange, setPasswordChange] = useState<string>("true")
    const [password, setPassword] = useState<string>("01pass1234")


    const submitForm = async (e: any) => {
        e.preventDefault();
        const body = {
            fullname: fullName,
            lan_id: userName,
            email: email,
            usergroup: userGroup,
            password: password,
            password_change: Boolean(passwordChange),
            is_active: Boolean(isActive),
            pilot_user: false
        }
        await createNewUser(body)
        getUserList()
        setFullName("")
        setEmail("")
        setUserName("")
        setUserGroup("")
        setIsActive("false")
        setPasswordChange("true")
        setPassword("01pass1234")
    }
    return (
        <div style={{ width: "100%" }} className="data-table">
            <form onSubmit={submitForm}>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-end"
                    spacing={2}
                    style={{ margin: "2rem auto", maxHeight: "345px", background: "#fff", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                >
                    <Grid item md={12} xs={12}>
                        <h4>CREATE USER ACCOUNT</h4>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <TextField
                            className={classes.labelText}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={fullName}
                            onChange={(e: any) => setFullName(e.target.value)}
                            InputLabelProps={{
                                shrink: true
                            }}
                            id="fullname"
                            label="Full Name"
                            autoComplete="fullname"
                            autoFocus
                            required
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <TextField
                            className={classes.labelText}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="username"
                            label="Username"
                            autoComplete="username"
                            autoFocus
                            value={userName}
                            onChange={(e: any) => setUserName(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <TextField
                            className={classes.labelText}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            autoComplete="email"
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                            InputLabelProps={{
                                shrink: true
                            }}
                            required
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <TextField
                            className={classes.labelText}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="password"
                            label="Password"
                            autoComplete="password"
                            autoFocus
                            value={password}
                            onChange={(e: any) => setPassword(e.target.value)}
                            InputLabelProps={{
                                shrink: true
                            }}
                            required
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <TextField
                            className={classes.labelText}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size='small'
                            select
                            id="usergroup"
                            label="Usergroup"
                            autoComplete="usergroup"
                            autoFocus
                            value={userGroup}
                            onChange={(e: any) => setUserGroup(e.target.value)}
                            InputLabelProps={{
                                shrink: true
                            }}
                            required
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value={"Branch"}>Branch</MenuItem>
                            <MenuItem value={"Admin"}>Admin</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <TextField
                            className={classes.labelText}
                            variant="outlined"
                            margin="normal"
                            size='small'
                            fullWidth
                            select
                            id="isactive"
                            label="Is Active"
                            autoComplete="isactive"
                            autoFocus
                            value={isActive}
                            onChange={(e: any) => setIsActive(e.target.value)}
                            InputLabelProps={{
                                shrink: true
                            }}
                            required
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value={"true"}>Active</MenuItem>
                            <MenuItem value={"false"}>Deactive</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            // color="#3f51b5"

                            style={{ marginBottom: "0.7rem", background: "#07639d" }}
                            // disabled={loading}
                            className={classes.submit}
                        >
                            Create Account
                        </Button>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            // color="error"
                            style={{ marginBottom: "0.7rem", background: "#616161" }}
                            // disabled={loading}
                            className={classes.submit}
                        >
                            Reset Form
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div >
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {

})(RegisterUserForm);
