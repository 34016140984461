import React, { useEffect } from "react";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import "./index.sass";
import {getUserList,createNewUser,updateUserProfile} from '../../action/adminAction'
import RegistrationForm from './registeruser'
import { createTheme, Grid } from "@mui/material";
import UserTable from './table'
import ActiveUserList from './activeUserList'
import DeactivateUserList from './deactivateUser'
const theme = createTheme();
const useStyles = makeStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      textTransform: "capitalize",
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

interface Props {
    getUserList:any;
    userList:any;
    createNewUser:any
    updateUserProfile:any;
}

const AdminHomePage: React.FC<Props> = ({
    getUserList,
    userList,
    createNewUser,
    updateUserProfile
}) => {
  const classes = useStyles();

  useEffect(()=>{
    getUserList()
  },[])


  return (
    <Grid container spacing={2} style={{marginTop:"auto"}}>
    <Grid item xs={6} style={{marginBottom:"auto"}}>
     <UserTable getUserList={getUserList} userList={userList}/>
    </Grid>
    <Grid item xs={6} style={{marginBottom:"auto"}}> 
     <RegistrationForm createNewUser={createNewUser} getUserList={getUserList} />
     </Grid>
    <Grid item xs={6} style={{marginBottom:"auto"}}> 
    <ActiveUserList getUserList={getUserList} userList={userList} updateUserProfile={updateUserProfile}/>
     </Grid>
    <Grid item xs={6} style={{marginBottom:"auto"}}> 
    <DeactivateUserList getUserList={getUserList} userList={userList} updateUserProfile={updateUserProfile}/>
     </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
    userList: state.AdminReducer.userList,
});

export default connect(mapStateToProps, {
    getUserList,
    createNewUser,
    updateUserProfile
})(AdminHomePage);
