import * as React from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { useTheme, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import PanAuthpage from './panAuth';
import PanAuthWithAadhaar from './panLinkWithAadhaar'
import PanLinkWithAadhaar from './panLinkWithAadhaar';

const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "80%",
        flexGrow: 21,
    },
})
interface Props {

}

const PanCardValidation: React.FC<Props> = ({ }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [tabNum,setTabNum]=React.useState<any>("PA")
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container>
                    <Grid item xs={6} md={6} sm={6}>
                        <Button fullWidth variant={tabNum==="PA"?"contained":"outlined"} onClick={()=>setTabNum("PA")}>PAN Authentication</Button>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6}>
                    <Button fullWidth variant={tabNum==="PLS"?"contained":"outlined"} onClick={()=>setTabNum("PLS")}>PAN Link Status (with any Aadhaar)</Button>
                    </Grid>
                </Grid>
                <Grid container>
                    {tabNum==="PA"?(
                        <PanAuthpage/>
                    ):(
                        <PanLinkWithAadhaar/> 
                    )}
                </Grid>
            </main>
        </>
    )
}

const mapStateToProps = (state: any) => ({

})
export default connect(mapStateToProps, {

})(PanCardValidation)
