import React, { useEffect, useState, useRef } from "react";
import logo from "../Logo/pramerica.jpg";
import { connect } from "react-redux";
// import moment from "moment";
import ReactToPrint from "react-to-print";
import { makeStyles } from '@mui/styles';
// import {createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Grid } from "@mui/material";
// const theme = createTheme();
const useStyles = makeStyles({
    listMainContainer: {
        fontFamily: "sans-serif",
        fontSize: "16px",
        fontWeight: 600,
        padding: "0 0.2rem 0.2rem 0.2rem",
        display: "flex",
        width: "100%"
    },
    LeftList: {
        fontFamily: "sans-serif",
        width: "40%",
        display: "inline"
    },
    rightList: {
        fontFamily: "sans-serif",
        width: "60%",
        display: "inline"
    },
    spamRecordL1: {
        fontFamily: "sans-serif",
        width: "40%",
        display: "inline"
    },
    spamRecordL2: {
        fontFamily: "sans-serif",
        width: "40%",
        display: "inline"
    },
    spamRecordL3: {
        fontFamily: "sans-serif",
        width: "40%",
        display: "inline"
    }
})
interface PropsCR {
    EmailDetailsData: any;
    customerName:any;
    applicationNumber:any
}

const EmailPdfPage: React.FC<PropsCR> = ({
    EmailDetailsData,
    customerName,
    applicationNumber,
}) => {
    const classes = useStyles();
    const componentRef = useRef<any>(null);
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);
    const reactToPrintTrigger = React.useCallback(() => {
        return <Button variant="contained"  style={{background:"#07639d"}}>Print Details</Button>;
    }, []);
    // const handleBack = () => {
    //     localStorage.removeItem("addonPrice");
        // history.goBack();
    // };

    return (
        <div
            style={{
                margin: "50px auto",
            }}
        >
            <Grid container xs={12} style={{ display: "flex", justifyContent: "left", textAlign: "left", marginLeft: "1.5rem" }}>
                <Grid xs={12} sm={12} md={12}>
                    <ReactToPrint
                        content={reactToPrintContent}
                        trigger={reactToPrintTrigger}
                    /></Grid>
            </Grid>
            <div
                style={{
                    width: "842px",
                    border: "1px solid grey",
                    display: "flex",
                    margin: 20,
                }}
            >
                <div
                    ref={componentRef}
                    style={{
                        margin: 20,
                    }}
                >
                    <div>
                        <meta charSet="UTF-8" style={{ fontFamily: "sans-serif" }} />
                        <meta
                            name="viewport"
                            // content="width=device-width, initial-scale=1.0"
                            style={{ fontFamily: "sans-serif" }}
                        />
                        <title style={{ fontFamily: "sans-serif" }}>Email details</title>
                        <div
                            className="invoiceContainer"
                            style={{
                                fontFamily: "sans-serif",
                                marginInline: "5vw",
                                marginTop: 0,
                            }}
                        >
                            <div id="divToPrint" style={{ fontFamily: "sans-serif" }}>

                                <div
                                    className="logoContainer"
                                    style={{
                                        display: "flex",
                                        padding: "1rem",
                                    }}
                                >
                                    <div style={{ width: "50%" }}>
                                        <img
                                            className="invoiceImage"
                                            src={logo}
                                            style={{ fontFamily: "sans-serif", height: "70px" }}
                                        /> | LIFE INSURANCE
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <span
                                            className="invoiceHeading"
                                            style={{
                                                float: "right",
                                                textAlign: "right",
                                                fontFamily: "sans-serif",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <strong style={{ fontFamily: "sans-serif" }}>
                                                Pramerica Life Insurance Limited
                                            </strong>
                                            <br />
                                            DLF Cyber City, Phase-III,
                                            <br />
                                            Gurugram-122002<br />
                                            Tel +91 124 4697000, Fax +91 124 4697200
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="invoiceGrid"
                                    style={{ marginTop: "-8px", display: "flex", backgroundColor: "#2969b3", textAlign: "center", justifyContent: "center" }}
                                >
                                    <h4 style={{ color: "#ffffff" }}>Email Id Verification Details</h4>
                                </div>
                                <div style={{ fontFamily: "sans-serif", marginTop: "1.5rem" }}>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Email Id
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.data?.email || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Application Number
                                        </div>
                                        <div className={classes.rightList}>
                                        {applicationNumber}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Disposable
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.data?.disposable ? "Yes" : "No" || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Accept All
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.data?.accept_all ? "Yes" : "No" || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Generic Email
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.data?.genric_email ? "Yes" : "No" || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Mail Exchanger
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.data?.webmail ? "Yes" : "No" || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Records
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.data?.mx_records ? "Yes" : "No" || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Valid Regular
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.data?.regexp ? "Yes" : "No" || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            SMTP Check
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.data?.smtp_check ? "Yes" : "No" || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Final Validity
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.data?.result ? "Yes" : "No" || "--"}
                                        </div>
                                    </div>

                                </div>
                                <div
                                    className="invoiceGrid"
                                    style={{display: "flex", backgroundColor: "#2969b3", textAlign: "center", justifyContent: "center",marginTop: "1.5rem" }}
                                >
                                    <h4 style={{ color: "#ffffff" }}>Domain Information</h4>
                                </div>
                                <div style={{ fontFamily: "sans-serif", marginTop: "1.5rem" }}>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Age Year
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.additional_info?.whois_info?.age_year || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Creation Date
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.additional_info?.whois_info?.creation_date || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Expiration Date
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.additional_info?.whois_info?.expiration_date || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Update Date
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.additional_info?.whois_info?.update_date || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Is Expired
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData?.result?.additional_info?.whois_info?.expired || "--"}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="invoiceGrid"
                                    style={{ display: "flex", backgroundColor: "#2969b3", textAlign: "center", justifyContent: "center",marginTop: "1.5rem" }}
                                >
                                    <h4 style={{ color: "#ffffff" }}>Spam Record</h4>
                                </div>
                                <table
                                    className="invoiceSummaryTable"
                                    style={{
                                        borderCollapse: "collapse",
                                        fontFamily: "sans-serif",
                                        fontSize: "12px",
                                        marginTop: "1rem",
                                        textAlign: "left",
                                        width: "100%",
                                    }}
                                >
                                    <tbody>
                                        <tr
                                            style={{
                                                fontFamily: "sans-serif",
                                                fontSize: "16px",
                                                fontWeight: 600,
                                                textAlign: "center",
                                                padding: "0 0.2rem 0.2rem 0.2rem",
                                            }}
                                        >
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                Spam Emai
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                IP Blacklist
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                }}
                                            >
                                                Report Count
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: 600,
                                                textAlign: "center",
                                                padding: "0 0.2rem 0.2rem 0.2rem",
                                                fontFamily: "sans-serif",
                                            }}
                                        >
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                {EmailDetailsData?.result?.additional_info?.spam_record?.spam_email?"Yes":"No" || "--"}
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                {EmailDetailsData?.result?.additional_info?.spam_record?.ip_blacklist?"Yes":"No" || "--"}
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                }}
                                            >
                                                {EmailDetailsData?.result?.additional_info?.spam_record?.report_count || "--"}

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div
                                    className="invoiceGrid"
                                    style={{ display: "flex", backgroundColor: "#2969b3", textAlign: "center", justifyContent: "center",marginTop: "1.5rem" }}
                                >
                                    <h4 style={{ color: "#ffffff" }}>Individual Match</h4>
                                </div>
                                <table
                                    className="invoiceSummaryTable"
                                    style={{
                                        borderCollapse: "collapse",
                                        fontFamily: "sans-serif",
                                        fontSize: "12px",
                                        marginTop: "1rem",
                                        textAlign: "left",
                                        width: "100%",
                                    }}
                                >
                                    <tbody>
                                        <tr
                                            style={{
                                                fontFamily: "sans-serif",
                                                fontSize: "16px",
                                                fontWeight: 600,
                                                textAlign: "center",
                                                padding: "0 0.2rem 0.2rem 0.2rem",
                                            }}
                                        >
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                Match
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                Name
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                }}
                                            >
                                                Score
                                            </td>
                                        </tr>
                                        {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result?.additional_info?.individual_match.map((data:any)=>{
                                            return(
                                                <tr
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: 600,
                                                    textAlign: "center",
                                                    padding: "0 0.2rem 0.2rem 0.2rem",
                                                    fontFamily: "sans-serif",
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        padding: ".6rem",
                                                        paddingRight: "1.6rem",
                                                    }}
                                                >
                                                    {data?.match?"Yes":"No"}
                                                </td>
                                                <td
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        padding: ".6rem",
                                                        paddingRight: "1.6rem",
                                                    }}
                                                >
                                                    {data?.name}
                                                </td>
                                                <td
                                                    style={{
                                                        fontFamily: "sans-serif",
                                                        padding: ".6rem",
                                                    }}
                                                >
                                                   {data?.score}
                                                </td>
                                            </tr>
                                            )
                                        })}
                                   
                                    </tbody>
                                </table>
                                <div
                                    className="invoiceGrid"
                                    style={{display: "flex", backgroundColor: "#2969b3", textAlign: "center", justifyContent: "center",marginTop: "1.5rem" }}
                                >
                                    <h4 style={{ color: "#ffffff" }}>Individual Name</h4>
                                </div>
                                {/* <table
                                    className="invoiceSummaryTable"
                                    style={{
                                        borderCollapse: "collapse",
                                        fontFamily: "sans-serif",
                                        fontSize: "12px",
                                        marginTop: "1rem",
                                        textAlign: "left",
                                        width: "100%",
                                    }}
                                >
                                    <tbody>
                                        <tr
                                            style={{
                                                fontFamily: "sans-serif",
                                                fontSize: "16px",
                                                fontWeight: 600,
                                                textAlign: "center",
                                                padding: "0 0.2rem 0.2rem 0.2rem",
                                            }}
                                        >
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                Match
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                Name
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                }}
                                            >
                                                Score
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: 600,
                                                textAlign: "center",
                                                padding: "0 0.2rem 0.2rem 0.2rem",
                                                fontFamily: "sans-serif",
                                            }}
                                        >
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                lkdfhlsa
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                lkdsfm
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                }}
                                            >
                                                ks;mdf
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> */}
                                <div
                                    className="invoiceGrid"
                                    style={{ display: "flex", backgroundColor: "#2969b3", textAlign: "center", justifyContent: "center",marginTop: "1.5rem" }}
                                >
                                    <h4 style={{ color: "#ffffff" }}>Organization Name</h4>
                                </div>
                                {/* <table
                                    className="invoiceSummaryTable"
                                    style={{
                                        borderCollapse: "collapse",
                                        fontFamily: "sans-serif",
                                        fontSize: "12px",
                                        marginTop: "1rem",
                                        textAlign: "left",
                                        width: "100%",
                                    }}
                                >
                                    <tbody>
                                        <tr
                                            style={{
                                                fontFamily: "sans-serif",
                                                fontSize: "16px",
                                                fontWeight: 600,
                                                textAlign: "center",
                                                padding: "0 0.2rem 0.2rem 0.2rem",
                                            }}
                                        >
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                Match
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                Name
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                }}
                                            >
                                                Score
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: 600,
                                                textAlign: "center",
                                                padding: "0 0.2rem 0.2rem 0.2rem",
                                                fontFamily: "sans-serif",
                                            }}
                                        >
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                lkdfhlsa
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                    paddingRight: "1.6rem",
                                                }}
                                            >
                                                lkdsfm
                                            </td>
                                            <td
                                                style={{
                                                    fontFamily: "sans-serif",
                                                    padding: ".6rem",
                                                }}
                                            >
                                                ks;mdf
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
    //   getBookingById,
})(EmailPdfPage);
