import * as React from 'react';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactToPrint from "react-to-print";
import { Button, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField } from '@mui/material';
import Webcam from "react-webcam";
import "./index.sass";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { validateFaceMatching, validateOcrKyc } from '../../action/adminAction'
import { useCallback, useEffect, useRef, useState } from 'react';
import Loader from '../../../components/loader';
import MobileAuthPage from '../MobileValidation/index'
import EmailValidationPage from '../EmailValidation'
import BankValidationPage from '../BankValidation/index'
import { ACTIONS } from '../../interfaces/adminType';
import PDFDetails from './verificationpdf'
import { getEkycAccessToken } from '../../../actions/loginActions';
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "100%",
        flexGrow: 21,
    },
    container: {
        width: "70%",
        flexGrow: 21,
    },
    labelText: {
        border: "1px solid",
        color: "red",

        '& label': {
            fontSize: "18px !important",
        }
    },
    listMainContainer: {
        fontFamily: "sans-serif",
        fontSize: "12px",
        fontWeight: 600,
        padding: "0 0.2rem 0.2rem 0.2rem",
        display: "flex",
        width: "100%"
    },
    LeftList: {
        fontFamily: "sans-serif",
        width: "50%",
        display: "inline"
    },
    rightList: {
        fontFamily: "sans-serif",
        width: "50%",
        display: "inline"
    }

})
const videoConstraints = {
    width: 350,
    height: 250,
    facingMode: "user"
};
interface Props {
    validateFaceMatching: any
    faceMatchLoading: any;
    faceMatchingResults: any;
    validateOcrKyc: any;
    ocrKycLoading: any;
    ocrKycdata: any;
    getEkycAccessToken:any
}

const EmailValidationForm: React.FC<Props> = ({
    validateFaceMatching,
    faceMatchLoading,
    faceMatchingResults,
    validateOcrKyc,
    ocrKycLoading,
    ocrKycdata,
    getEkycAccessToken,

}) => {
    const classes = useStyles();
    // const theme = createTheme();
    // let navigate = useNavigate();
    let dispatch = useDispatch();
    let baseB64String = "data:image/jpeg;base64,";
    const [policyNumber, setPolicyNumber] = React.useState("")
    const [visitPurpose, setVisitPurpose] = useState<any>("")
    const [policyNumberError, setPolicyNumberError] = useState<any>("")
    const [picture, setPicture] = useState('')
    const [picture1, setPicture1] = useState('')
    const [ocrPicture, setOcrPicture] = useState('')
    const [openWebCam, setOpenWebCam] = useState<boolean>(false)
    const [openFaceWebCam, setOpenFaceWebCam] = useState<boolean>(false)
    const [servicePage, setServicePage] = useState<any>("")
    const [state, setState] = React.useState({
        maskAadhaar: false,
        docType: false,
        hideAadhaar: false,
        conf: false,
    });
    //PDF Function
    const componentRef = useRef<any>(null);
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);
    const reactToPrintTrigger = React.useCallback(() => {
        return <Button
            variant="contained"
            style={{background:"#07639d"}}
        >Print Details
        </Button>;
    }, []);
    //WebCam image capture and image reference
    const webcamRef = useRef<Webcam>(null);
    const webcam2Ref = useRef<Webcam>(null);
    const webcam3Ref = useRef<Webcam>(null);


    const capture = useCallback(() => {
        const pictureSrc = webcamRef?.current?.getScreenshot()
        setPicture(pictureSrc ? pictureSrc : "")
    }, []);
    const captureSecondCamera = useCallback(() => {
        const pictureSrc1 = webcam3Ref?.current?.getScreenshot()
        console.log("clicke", pictureSrc1)
        setPicture1(pictureSrc1 ? pictureSrc1 : "")
    }, []);

    const captureOcrPic = useCallback(() => {
        const ocrPictureSrc = webcam2Ref?.current?.getScreenshot()
        setOcrPicture(ocrPictureSrc ? ocrPictureSrc : "")
    }, []);

    //Base64 to formdata converter
    // const DataURIToBlob = (dataURI: string) => {
    //     const splitDataURI = dataURI.split(',')
    //     const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    //     const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    //     const ia = new Uint8Array(byteString.length)
    //     for (let i = 0; i < byteString.length; i++)
    //         ia[i] = byteString.charCodeAt(i)

    //     return new Blob([ia], { type: mimeString })
    // }

    //Visit Purpose dropdown change
    // const handleChange = (event: any) => {
    //     setVisitPurpose(event.target.value);
    // };
    //Cocument Type switch change
    const handleDocTypeChange = (event: any) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };
    //WebCam Open function
    const handleOpenWebCam = () => {
        if (policyNumber !== "") {
            if (policyNumber.length !== 8) {
                setPolicyNumberError("Please provide valid 8 digit policy number")
            }
            else {
                setOpenWebCam(!openWebCam)
                // localStorage.setItem('policynumber', policyNumber);
                setPolicyNumberError("")
            }
        }
        else {
            setPolicyNumberError("please provide policy number.....")
        }
    }
    //Validation and Api Call
    const validateFaceMatch = async (e: any) => {
        e.preventDefault()
        const body = {
            image1B64: picture.replace(baseB64String, ""),
            image2B64: picture1.replace(baseB64String, "")
        }
        // // validateFaceMatching(formData)
        await validateFaceMatching(body)
    }
    const validateIdCard = async (e: any) => {

        e.preventDefault()
        const data = {
            fileB64: ocrPicture.replace(baseB64String, ""),
            maskAadhaar: state.maskAadhaar,
            hideAadhaar: state.hideAadhaar,
            conf: state.conf,
            docType: state.conf ? "d1" : ""
        }
        await validateOcrKyc(data)

    }

    //Localstorage call to store ocr name value
    useEffect(() => {
        if (ocrKycdata && ocrKycdata?.result && ocrKycdata?.result[0]?.details?.name) {
            localStorage.setItem('ocrname', ocrKycdata?.result[0]?.details?.name);
        }

    }, [ocrKycdata])
    useEffect(() => {
        if (!openWebCam) {
            dispatch({ type: ACTIONS.VALIDATE_OCR_KYC, payload: [] });
            dispatch({ type: ACTIONS.VALIDATE_FACE_MATCHING, payload: [] });
            dispatch({ type: ACTIONS.POST_PAN_LINK_WITH_AADHAAR_AUTHENTICATION_DATA, payload: [] });
            dispatch({ type: ACTIONS.POST_MOBILE_DETAILS_DATA, payload: [] });
            dispatch({ type: ACTIONS.POST_VALIDATE_OTP_INPUT, payload: [] });
            dispatch({ type: ACTIONS.POST_PAN_AUTHENTICATION_DATA, payload: [] });
            dispatch({ type: ACTIONS.POST_BANK_AUTHENTICATION_DATA, payload: [] });
            dispatch({ type: ACTIONS.POST_EMAIL_AUTHENTICATION_DATA, payload: [] });
            dispatch({ type: ACTIONS.GET_MOBILE_OTP, payload: [] });
        }
    }, [openWebCam])
    useEffect(()=>{
        getEkycAccessToken()
    })
    return (
        <div style={{ width: "100%" }}>
            <div className={classes.toolbar} />
            {/*Policy Number container start*/}
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        className={classes.labelText}
                        required
                        onInput={(e: any) => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 8);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onWheel={(e: any) => e.target.blur()}
                        fullWidth
                        type="number"
                        id="policyNumber"
                        label="Enter Policy Number"
                        name="policyNumber"
                        autoComplete="policyNumber"
                        value={policyNumber}
                        disabled={openWebCam}
                        onChange={(e: any) => {
                            setPolicyNumber(e.target.value)
                            if (policyNumber.length === 8) {
                                setPolicyNumberError("")
                            }
                        }}

                    />
                    <p style={{ color: "red" }}>{policyNumberError}</p>
                </Grid>

                <Grid item xs={3} sm={3} md={3}>
                    <Button variant="contained"  style={{background:"#07639d"}} onClick={handleOpenWebCam}>{!openWebCam ? "Open WebCam" : "Close Webcam"}</Button>
                </Grid>
                {(ocrKycdata && ocrKycdata?.statusCode) === 101 || (faceMatchingResults && faceMatchingResults?.statusCode) === 101 ? (
                    <Grid item xs={3} sm={3} md={3}>
                        <ReactToPrint
                            content={reactToPrintContent}
                            trigger={reactToPrintTrigger}
                        />
                    </Grid>
                ) : ""}


            </Grid>
            {/*Policy Number container End*/}
            {/*Webcame container Start*/}
            <div className={classes.toolbar} />
            {openWebCam ? (
                <Grid container spacing={3}>
                    {/* {openOcrWebCam ? ( */}
                    <Grid item xs={12} sm={12} md={5} >

                        <Grid container spacing={2} style={{ border: "1px solid black", height: "400px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset" }}>
                            <Grid item xs={6} sm={6} md={6}>
                                <h4>ID VERIFICATION</h4>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        {ocrPicture === '' ? (
                                            <Webcam
                                                audio={false}
                                                height={240}
                                                ref={webcam2Ref}
                                                width={350}
                                                mirrored={false}
                                                imageSmoothing={true}
                                                screenshotFormat="image/jpeg"
                                                videoConstraints={videoConstraints}
                                            />
                                        ) : (
                                            <>
                                                <img src={ocrPicture} alt={ocrPicture} />
                                                {ocrKycdata && ocrKycdata?.statusCode === 102 ? (
                                                    <p style={{ color: "red" }}>Id card image is not clear!!!!</p>
                                                ) : ""}

                                            </>

                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        {ocrPicture !== '' ? (
                                            <Stack direction="row" spacing={2} style={{ margin: "2px" }}>
                                                <Button
                                                    variant="contained"
                                                    style={{ background: "#07639d" }}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setOcrPicture('')
                                                    }}
                                                    className="btn btn-primary"
                                                >
                                                    <RestartAltIcon />
                                                </Button>
                                                <Button
                                                    onClick={(e: any) => validateIdCard(e)}
                                                    variant="contained"
                                                    style={{ background: "#07639d" }}
                                                >
                                                    Validate Id
                                                </Button>

                                                {/* {ocrKycdata && ocrKycdata?.statusCode === 101 ? (
                                                        <button
                                                            onClick={(e: any) => validateFaceMatch(e)}
                                                            className="btn btn-primary"
                                                        >
                                                            Validate Face Match
                                                        </button>
                                                    ) : ""} */}
                                            </Stack>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                style={{ background: "#07639d" }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    captureOcrPic()
                                                }}
                                            >
                                                Capture
                                            </Button>
                                        )}
                                        {ocrKycLoading ? <Loader /> : faceMatchLoading ? <Loader /> : ""}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} >
                                <FormControl component="fieldset" variant="standard" style={{ width: '80%', maxWidth: 360, background: 'background.paper', marginLeft: "3.5rem" }} >
                                    <FormLabel component="legend" style={{ width: '75%', marginLeft: "5rem" }} >If Required</FormLabel>
                                    <FormGroup style={{ width: '100%', marginLeft: "1rem" }} >
                                        <FormControlLabel
                                            style={{ fontSize: "10px" }}
                                            control={
                                                <Switch checked={state.maskAadhaar} onChange={handleDocTypeChange} name="maskAadhaar" />
                                            }
                                            label="If the document is an Aadhar card,is the masked Aadhaar image required"
                                        />
                                        <FormControlLabel
                                            style={{ fontSize: "10px" }}
                                            control={
                                                <Switch checked={state.docType} onChange={handleDocTypeChange} name="docType" />
                                            }
                                            label="Is the document a Driving Licence"
                                        />
                                        <FormControlLabel
                                            style={{ fontSize: "10px !imported" }}
                                            control={
                                                <Switch checked={state.hideAadhaar} onChange={handleDocTypeChange} name="hideAadhaar" />
                                            }
                                            label="If the document is an Aadhaar card, is the masked Aadhaar number required?"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Switch checked={state.conf} onChange={handleDocTypeChange} name="conf" />
                                            }
                                            label="Is the Confidence score required?"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* ) : ""} */}
                    {openFaceWebCam ? (
                        <Grid item xs={12} sm={12} md={7}>
                            <Grid container spacing={2} style={{ border: "1px solid black", height: "400px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset" }}>
                                <Grid item xs={6} sm={6} md={6}>
                                    <h4>LIVE CUSTOMER PHOTO</h4>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            {picture === '' ? (
                                                <Webcam
                                                    audio={false}
                                                    // height={240}
                                                    ref={webcamRef}
                                                    // width={350}
                                                    mirrored={false}
                                                    screenshotFormat="image/jpeg"
                                                    videoConstraints={videoConstraints}
                                                />
                                            ) : (
                                                <img src={picture} alt={picture} />
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            {picture !== '' ? (
                                                <Stack direction="row" spacing={2} style={{ margin: "2px" }}>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setPicture('')
                                                        }}
                                                        variant="contained"
                                                        style={{ background: "#07639d" }}
                                                    >
                                                        <RestartAltIcon />
                                                    </Button>
                                                    <Button
                                                         variant="contained"
                                                        onClick={(e: any) => validateFaceMatch(e)}
                                                        style={{ background: "#07639d" }}
                                                    >
                                                        Face Match
                                                    </Button>
                                                </Stack>
                                            ) : (
                                                <Button
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        capture()
                                                    }}
                                                    variant="contained"
                                                    style={{background:"#07639d"}}
                                                >
                                                    Capture
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <h4>CUSTOMER ID CARD</h4>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            {picture1 === '' ? (
                                                <Webcam
                                                    audio={false}
                                                    // height={240}
                                                    ref={webcam3Ref}
                                                    // width={350}
                                                    mirrored={false}
                                                    screenshotFormat="image/jpeg"
                                                    videoConstraints={videoConstraints}
                                                />
                                            ) : (
                                                <img src={picture1} alt={picture1} />
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            {picture1 !== '' ? (
                                                <Stack direction="row" spacing={2} style={{ margin: "2px" }}>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setPicture1('')
                                                        }}
                                                        variant="contained"
                                                        style={{background:"#07639d"}}
                                                    >
                                                        <RestartAltIcon />
                                                    </Button>
                                                </Stack>
                                            ) : (
                                                <Button
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        captureSecondCamera()
                                                    }}
                                                    variant="contained"
                                                    style={{background:"#07639d"}}
                                                >
                                                    Capture
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={12} md={7}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => setOpenFaceWebCam(true)}
                                style={{background:"#07639d"}}
                            >
                                Face Verification
                            </Button>
                        </Grid>
                    )}


                </Grid>
            ) : ""}
            {/*Webcame container End*/}
            {/*Auth container Start*/}
            {(ocrKycdata && ocrKycdata?.statusCode) === 101 || (faceMatchingResults && faceMatchingResults?.statusCode) === 101 ? (
                <Grid container spacing={2} style={{ marginTop: "1.5rem", boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset" }}>
                    <Grid xs={6} sm={6} md={6} style={{ border: "1px solid black", padding: "1rem" }} >
                        <Grid xs={12} sm={12} md={12} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Purpose of Visit...</InputLabel>
                                <Select
                                    className='input'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={visitPurpose}
                                    label="Purpose of Visit..."
                                    onChange={(e: any) => {
                                        setVisitPurpose(e.target.value)
                                        setServicePage("")
                                    }
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"servie"}>Service inquiry</MenuItem>
                                    <MenuItem value={"claim"}>Claim inquiry</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <br />
                        <Grid xs={12} sm={12} md={12} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Service Type</InputLabel>
                                <Select
                                    className='input'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={servicePage}
                                    label="Service Type..."
                                    onChange={(e: any) => setServicePage(e.target.value)}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {visitPurpose !== "claim" && visitPurpose !== "" ? (
                                        <MenuItem value={"mobile"}>Mobile Authentication</MenuItem>
                                    ) : ""}
                                    {visitPurpose !== "claim" && visitPurpose !== "" ? (
                                        <MenuItem value={"email"}>Email Authentication</MenuItem>
                                    ) : ""}
                                    {visitPurpose !== "" ? (
                                        <MenuItem value={"bank"}>Bank Authentication</MenuItem>
                                    ) : ""}
                                    {visitPurpose !== "" ? (
                                        <MenuItem value={"ekyc"}>Ekyc Authentication</MenuItem>
                                    ) : ""}

                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid xs={6} sm={6} md={6} style={{ border: "1px solid black" }} >
                        {ocrKycdata && ocrKycdata?.statusCode === 101 ? (
                            <div style={{ fontFamily: "sans-serif", marginLeft: "1rem" }}>
                                <br />
                                <h5>OCR RESULTS</h5>
                                <div
                                    className={classes.listMainContainer}
                                >
                                    <div className={classes.LeftList} >
                                        Document Type :
                                    </div>
                                    <div className={classes.rightList}>
                                        {ocrKycdata && ocrKycdata?.result[0]?.type}                                            </div>
                                </div>
                                <div
                                    className={classes.listMainContainer}
                                >
                                    <div className={classes.LeftList} >
                                        Name :
                                    </div>
                                    <div className={classes.rightList}>
                                        {ocrKycdata && ocrKycdata?.result[0]?.details?.name?.value}                                            </div>
                                </div>
                                <div
                                    className={classes.listMainContainer}
                                >
                                    <div className={classes.LeftList} >
                                        Father Name :
                                    </div>
                                    <div className={classes.rightList}>
                                        {ocrKycdata && ocrKycdata?.result[0]?.details?.father?.value}                                            </div>
                                </div>
                                {ocrKycdata && ocrKycdata?.result[0]?.type === "Pan" ? (
                                    <div>
                                        <div
                                            className={classes.listMainContainer}
                                        >
                                            <div className={classes.LeftList} >
                                                Pan Number :
                                            </div>
                                            <div className={classes.rightList}>
                                                {ocrKycdata && ocrKycdata?.result[0]?.details?.panNo?.value}                                            </div>
                                        </div>
                                        <div
                                            className={classes.listMainContainer}
                                        >
                                            <div className={classes.LeftList} >
                                                DOB :
                                            </div>
                                            <div className={classes.rightList}>
                                                {ocrKycdata && ocrKycdata?.result[0]?.details?.date?.value}                                            </div>
                                        </div>
                                    </div>
                                ) : ocrKycdata && ocrKycdata?.result[0]?.type.includes("Aadhaar") ? (
                                    <div>
                                        <div
                                            className={classes.listMainContainer}
                                        >
                                            <div className={classes.LeftList} >
                                                Aadhaar Number :
                                            </div>
                                            <div className={classes.rightList}>
                                                {ocrKycdata && ocrKycdata?.result[0]?.details?.aadhaar?.value}                                            </div>
                                        </div>
                                        <div
                                            className={classes.listMainContainer}
                                        >
                                            <div className={classes.LeftList} >
                                                Gender :
                                            </div>
                                            <div className={classes.rightList}>
                                                {ocrKycdata && ocrKycdata?.result[0]?.details?.gender?.value}                                            </div>
                                        </div>
                                        <div
                                            className={classes.listMainContainer}
                                        >
                                            <div className={classes.LeftList} >
                                                DOB :
                                            </div>
                                            <div className={classes.rightList}>
                                                {ocrKycdata && ocrKycdata?.result[0]?.details?.dob?.value}                                            </div>
                                        </div>
                                    </div>
                                ) : ""}
                            </div>
                        ) : ""}
                        {faceMatchingResults && faceMatchingResults?.statusCode === 101 ? (
                            <div style={{ fontFamily: "sans-serif", marginLeft: "1rem" }}>
                                <br />
                                <h5>FACE MATCH RESULTS</h5>
                                <div
                                    className={classes.listMainContainer}
                                >
                                    <div className={classes.LeftList} >
                                        Face Match :
                                    </div>
                                    <div className={classes.rightList}>
                                        {faceMatchingResults && faceMatchingResults?.result && faceMatchingResults?.result?.match}
                                    </div>
                                </div>
                                <div
                                    className={classes.listMainContainer}
                                >
                                    <div className={classes.LeftList} >
                                        Match Score :
                                    </div>
                                    <div className={classes.rightList}>
                                        {faceMatchingResults && faceMatchingResults?.result && `${Math.round(faceMatchingResults?.result?.matchScore)}%`}
                                    </div>
                                </div>
                                <div
                                    className={classes.listMainContainer}
                                >
                                    <div className={classes.LeftList} >
                                        Confidance
                                    </div>
                                    <div className={classes.rightList}>
                                        {faceMatchingResults && faceMatchingResults?.result && `${Math.round(faceMatchingResults?.result?.confidence)}%`}
                                    </div>
                                </div>
                                <div
                                    className={classes.listMainContainer}
                                >
                                    <div className={classes.LeftList} >
                                        Review Needed :
                                    </div>
                                    <div className={classes.rightList}>
                                        {faceMatchingResults && faceMatchingResults?.result && faceMatchingResults?.result?.reviewNeeded}
                                    </div>
                                </div>
                            </div>
                        ) : ""}
                    </Grid>
                </Grid >
            ) : ""}
            {/*Auth container End*/}
            {servicePage === "mobile" ? (
                <Grid container spacing={2}>
                    <MobileAuthPage />
                </Grid>
            ) : servicePage === "email" ? (
                <Grid container spacing={2}>
                    <EmailValidationPage />
                </Grid>
            ) : servicePage === "bank" ? (
                <Grid container spacing={2}>
                    <BankValidationPage />
                </Grid>
            ) : ""}
            <div style={{ display: "none" }}>
                <PDFDetails
                    componentRef={componentRef}
                    picture={picture}
                    picture1={picture1}
                    ocrPicture={ocrPicture}
                    ocrKycdata={ocrKycdata}
                    faceMatchingResults={faceMatchingResults}
                />
            </div>
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    faceMatchingResults: state.AdminReducer.faceMatchingResults,
    faceMatchLoading: state.AdminReducer.faceMatchLoading,
    ocrKycdata: state.AdminReducer.ocrKycdata,
    ocrKycLoading: state.AdminReducer.ocrKycLoading,
})
export default connect(mapStateToProps, {
    validateFaceMatching,
    validateOcrKyc,
    getEkycAccessToken
})(EmailValidationForm)
