import * as React from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { useTheme, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { PostPANLinkWithAadhaarAuthentication } from '../../action/adminAction'
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "80%",
        flexGrow: 21,
    },
    labelText: {
        border: "1px solid",
        color: "red",

        '& label': {
            fontSize: "18px !important",
        }
    },
})
interface Props {
    PostPANLinkWithAadhaarAuthentication: any;
    panLinkDetail: any;
    panLinkLoader: any;
}

const PanLinkWithAadhaarAuthentication: React.FC<Props> = ({
    PostPANLinkWithAadhaarAuthentication,
    panLinkDetail,
    panLinkLoader
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const [panNumber, setPanNumber] = React.useState<any>("")
    const handlePanValidation = async (e: any) => {
        e.preventDefault()
        const body = {
            pan: panNumber,
            consent: "Y"
        }
        await PostPANLinkWithAadhaarAuthentication(body)
    }
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />

                <Grid container spacing={3}>
                    <Grid container spacing={2} item xs={6} md={6} sm={6}>
                        <Grid item xs={12} md={12} sm={12}>
                            <TextField
                            className={classes.labelText}
                                id="outlined-multiline-flexible"
                                label="Pan Number"
                                placeholder='Enter pan number'
                                multiline
                                fullWidth
                                maxRows={4}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">
                                        <Tooltip title="Pan Card number example: ABCDE1234N" placement="top-end">
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>,
                                }}
                                InputLabelProps={{
                                    shrink:true
                                  }}
                                value={panNumber}
                                onChange={(e: any) => setPanNumber(e.target.value.toUpperCase())}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} sm={6}>
                            <Button variant="contained" disabled={panNumber === ""} style={{ background: "#07639d" }} onClick={handlePanValidation}>Validate Pan</Button>
                        </Grid>
                        <Grid item xs={6} md={6} sm={6}>
                            <Button variant="contained" style={{background:"#616161"}} onClick={() => window.location.reload()}>Reset Data</Button>
                        </Grid>
                    </Grid>
                        {panLinkLoader ? (
                            <Grid item xs={3} sm={3} md={3}>
                                <Card sx={{ display: 'flex' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <CircularProgress  style={{background:"#07639d"}} />
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Grid>
                        ) :
                            panLinkDetail && panLinkDetail["status-code"] === "101" ? (
                                <Grid item xs={3} sm={3} md={3}>
                                    <Card sx={{ display: 'flex' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                <Typography component="div" variant="h5">
                                                    Status
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                    {panLinkDetail?.result?.isAadhaarLinked ? "Aadhaar linked with pan" : "Aadhaar Not Linked"}
                                                </Typography>
                                            </CardContent>
                                        </Box>
                                    </Card>
                                </Grid>
                            ) : ""}
                    </Grid>
            </main>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    panLinkDetail: state.AdminReducer.panLinkDetail,
    panLinkLoader: state.AdminReducer.panLinkLoader
})
export default connect(mapStateToProps, {
    PostPANLinkWithAadhaarAuthentication
})(PanLinkWithAadhaarAuthentication)