import { ACTIONS, ValidationDashboarType } from "../interfaces/branchServiceType";

type InitialState = {
  sendOtploading: boolean;
  otpValidateLoding: boolean;
  mobileDetailsLoading: boolean;
  EmailDetailsLoading: boolean;
  BankDetailsLoading: boolean;
  panLoader:boolean;
  panLinkLoader:boolean;
  faceMatchLoading:boolean;
  ocrKycLoading:boolean;
  otpRequestData: Array<any>;
  validateReqData: Array<any>;
  MobileDetailsData: Array<any>;
  EmailDetailsData: Array<any>;
  BankDetailsData: Array<any>;
  panDetails:Array<any>;
  panLinkDetail:Array<any>
  faceMatchingResults:Array<any>
  ocrKycdata:Array<any>

};

const initialState: InitialState = {
  sendOtploading: false,
  otpValidateLoding: false,
  mobileDetailsLoading: false,
  EmailDetailsLoading: false,
  BankDetailsLoading: false,
  panLoader:false,
  panLinkLoader:false,
  faceMatchLoading:false,
  ocrKycLoading:false,
  otpRequestData: [],
  validateReqData: [],
  MobileDetailsData: [],
  EmailDetailsData: [],
  BankDetailsData: [],
  panDetails:[],
  panLinkDetail:[],
  faceMatchingResults:[],
  ocrKycdata:[],


};

const BranchServiceReducer = (state = initialState, action: ValidationDashboarType) => {
  switch (action.type) {
    case ACTIONS.SET_OTP_LOADING:
      return {
        ...state,
        sendOtploading: action.payload,
      };
    case ACTIONS.SET_MOBILE_DETAILS_LOADING:
      return {
        ...state,
        mobileDetailsLoading: action.payload,
      };
    case ACTIONS.SET_VALIDATE_OTP_LOADING:
      return {
        ...state,
        otpValidateLoding: action.payload,
      };
    case ACTIONS.SET_EMAIL_AUTH_LOADING:
      return {
        ...state,
        EmailDetailsLoading: action.payload,
      };
    case ACTIONS.SET_PAN_LOADING:
      return {
        ...state,
        panLoader: action.payload,
      };
    case ACTIONS.SET_PAN_LINK_LOADING:
      return {
        ...state,
        panLinkLoader: action.payload,
      };
    case ACTIONS.SET_BANK_AUTH_LOADING:
      return {
        ...state,
        BankDetailsLoading: action.payload,
      };
    case ACTIONS.SET_OCR_KYC_LOADING:
      return {
        ...state,
        ocrKycLoading: action.payload,
      };
    case ACTIONS.SET_FACE_MATCHING_LOADING:
      return {
        ...state,
        faceMatchLoading: action.payload,
      };
    case ACTIONS.GET_MOBILE_OTP:
      return {
        ...state,
        otpRequestData: action.payload,
        sendOtploading: false,
      };
    case ACTIONS.POST_VALIDATE_OTP_INPUT:
      return {
        ...state,
        validateReqData: action.payload,
        otpValidateLoding: false,
      };
    case ACTIONS.POST_MOBILE_DETAILS_DATA:
      return {
        ...state,
        MobileDetailsData: action.payload,
        mobileDetailsLoading: false,
      };
    case ACTIONS.POST_EMAIL_AUTHENTICATION_DATA:
      return {
        ...state,
        EmailDetailsData: action.payload,
        EmailDetailsLoading: false,
      };
    case ACTIONS.POST_BANK_AUTHENTICATION_DATA:
      return {
        ...state,
        BankDetailsData: action.payload,
        BankDetailsLoading: false,
      };
    case ACTIONS.POST_PAN_AUTHENTICATION_DATA:
      return {
        ...state,
        panDetails: action.payload,
        panLoader: false,
      };
    case ACTIONS.POST_PAN_LINK_WITH_AADHAAR_AUTHENTICATION_DATA:
      return {
        ...state,
        panLinkDetail: action.payload,
        panLinkLoader: false,
      };
    case ACTIONS.VALIDATE_FACE_MATCHING:
      return {
        ...state,
        faceMatchingResults: action.payload,
        faceMatchLoading: false,
      };
    case ACTIONS.VALIDATE_OCR_KYC:
      return {
        ...state,
        ocrKycdata: action.payload,
        ocrKycLoading: false,
      };
    default:
      return state;
  }
};

export default BranchServiceReducer;