import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import "./index.sass";
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import { getUserList } from '../../action/adminAction'
import { Avatar, createTheme, Grid, LinearProgress, MenuItem, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { ACTIONS } from '../../interfaces/adminType'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from "@mui/material";
const theme = createTheme();
const useStyles = makeStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            textTransform: "capitalize",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: "#2196f3"
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        submit: {
            margin: theme.spacing(3, 0, 2)
        },
        labelText: {
            border: "1px solid",
            color: "red",
    
            '& label': {
                fontSize: "18px !important",
            }
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
            height: "100%",
            // display: "flex",
            top: "5%",
            flexDirection: "column",
            justifyContent: "space-around",
        },
        input: {
           border:"1px solid red"
          },
    })
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

interface Props {
    policyNumber: any;
    setPolicyNumber: any;
    customerName: any;
    setCustomerName: any;
    mobileNumber: any;
    setMobileNumber: any;
    otp: any;
    setOtp: any;
    GetMobileOtp: any;
    otpRequestData: any;
    validateOtpInput: any;
    otpValidateLoding: any;
    sendOtploading: any;
}

const BookingsTableOffline: React.FC<Props> = ({
    policyNumber,
    setPolicyNumber,
    customerName,
    setCustomerName,
    mobileNumber,
    setMobileNumber,
    otp,
    setOtp,
    GetMobileOtp,
    otpRequestData,
    validateOtpInput,
    otpValidateLoding,
    sendOtploading

}) => {
    const { register, reset, handleSubmit, watch, formState: { errors } } = useForm();
    const classes = useStyles();
    const dispatch = useDispatch()

    const submitForm = async (e: any) => {
        e.preventDefault();
        const data = {
            mobile: mobileNumber,
            consent: "Y"
        }
        GetMobileOtp(data)
    }
    const handleValidateOtp = async (e: any) => {
        e.preventDefault();
        const body = {
            otp,
            request_id: otpRequestData?.request_id
        }
        await validateOtpInput(body)
        dispatch({ type: ACTIONS.POST_MOBILE_DETAILS_DATA, payload: [] });

    }
    return (
        <div style={{ width: "100%" }} className="data-table">
            <form onSubmit={submitForm}>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-end"
                    spacing={2}
                    style={{ margin: "2rem auto", background: "#fff", boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}
                >
                    <Grid item container alignItems="center" direction="column">
                        <Grid item>
                            <Avatar className={classes.avatar} sx={{ background: "#002984" }}>
                                <MobileFriendlyIcon />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography component="h1" variant="h5">
                                Mobile Authentication
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <TextField
                            className={classes.labelText}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={policyNumber}
                            // style={{border:"1px solid"}}
                            InputLabelProps={{
                                shrink:true
                              }}
                            onChange={(e: any) => setPolicyNumber(e.target.value)}
                            id="policyNumber"
                            label="Policy Number"
                            autoComplete="fullname"
                            autoFocus
                            required
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <TextField
                            className={classes.labelText}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="customerName"
                            label="Customer Name"
                            autoComplete="customerName"
                            autoFocus
                            value={customerName}
                            onChange={(e: any) => setCustomerName(e.target.value)}
                            InputLabelProps={{
                                shrink:true
                              }}
                            required
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <TextField
                            className={classes.labelText}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="mobileNumber"
                            label="Mobile Number"
                            autoComplete="mobileNumber"
                            value={mobileNumber}
                            onChange={(e: any) => setMobileNumber(e.target.value)}
                            InputLabelProps={{
                                shrink:true
                              }}
                            required
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            style={{ marginBottom: "0.7rem",background:"#07639d" }}
                            // disabled={loading}
                            className={classes.submit}
                        >
                            Verify Mobile
                        </Button>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            style={{ marginBottom: "0.7rem",background:"#616161"}}
                            endIcon={<RestartAltIcon />}
                            onClick={()=>window.location.reload()}
                        >
                            Reset All/New Request
                        </Button>
                    </Grid>


                </Grid>
            </form>
            {sendOtploading ? (<LinearProgress color="secondary" />) : ""}
            {otpRequestData && otpRequestData?.result && otpRequestData?.result?.message === "Otp has been sent to your mobile number" ? (
                <form onSubmit={handleValidateOtp}>
                    <Grid
                        container
                        direction="row"
                        alignItems="flex-end"
                        spacing={2}
                        style={{ margin: "2rem auto", background: "#eff" }}
                    >
                        <Grid item md={3} sm={3} xs={3}>
                            <TextField
                                className={classes.labelText}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="otp"
                                label="Enter OTP"
                                autoComplete="otp"
                                value={otp}
                                onChange={(e: any) => setOtp(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                style={{ marginBottom: "0.7rem",background:"#07639d" }}
                                className={classes.submit}
                            >
                                Verify OTP
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            ) : ""}
        </div >
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {

})(BookingsTableOffline);
