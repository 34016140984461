import React from "react";
import BranchServiceDashboardLeft from "../../BranchServiceDashboard/dashboard-left";
import BranchServiceDashboardRight from "../../BranchServiceDashboard/dashboard-right";
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}

const SampleAccessioningDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <BranchServiceDashboardLeft />
            <BranchServiceDashboardRight>{children && children}</BranchServiceDashboardRight>
        </div>
    );
};

export default SampleAccessioningDash;

