import * as React from 'react';
import { useTheme, createTheme } from '@mui/material/styles';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { PostEmailAuthentication } from '../../action/adminAction'
import { useState } from 'react';
import ValidationForm from './validationForm'
import EmailPdfPage from './emailPdf';
import Loader from '../../../components/loader';
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "100%",
        flexGrow: 21,
    },
})
interface Props {
    PostEmailAuthentication: any
    EmailDetailsData: any;
    EmailDetailsLoading: any;
}
const MobilevalidationPage: React.FC<Props> = ({
    PostEmailAuthentication,
    EmailDetailsData,
    EmailDetailsLoading
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch()
    const [policyNumber, setPolicyNumber] = useState<string>("")
    const [customerName, setCustomerName] = useState<string>("")
    const [email, setEmail] = useState<any>("")

    console.log(EmailDetailsData, "EmailDetailsData")
    return (
        <Grid container spacing={3} style={{ marginTop: "auto" }}>
            <Grid item xs={6} style={{ marginBottom: "auto" }}>
                <ValidationForm
                    policyNumber={policyNumber}
                    setPolicyNumber={setPolicyNumber}
                    customerName={customerName}
                    setCustomerName={setCustomerName}
                    email={email}
                    setEmail={setEmail}
                    PostEmailAuthentication={PostEmailAuthentication}
                />
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "auto" }}>
                {EmailDetailsLoading?<Loader/>:""}
                {EmailDetailsData && EmailDetailsData.result ? (
                    <EmailPdfPage EmailDetailsData={EmailDetailsData} customerName={customerName} applicationNumber={policyNumber} />

                ) : ""}
            </Grid>
        </Grid>
    );
};
const mapStateToProps = (state: any) => ({
    EmailDetailsData: state.AdminReducer.EmailDetailsData,
    EmailDetailsLoading: state.AdminReducer.EmailDetailsLoading
});

export default connect(mapStateToProps, {
    PostEmailAuthentication
})(MobilevalidationPage);