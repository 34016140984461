import React from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import { TextField,Typography, Avatar, Button, CssBaseline, Container, Grid } from '@mui/material';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import {createTheme } from '@mui/material/styles';
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "100%",
        flexGrow: 21,
    },
    grid: {
        backgroundColor: "grey",
        height: "25vh",
        textAlign: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#2196f3"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    paper: {
        marginTop: theme.spacing(5),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
})
interface Props {
    mobileNumber: any;
    setMobileNumber: any;
    mobileValidation: any;
    setMobileValidation: any;
    sendOtploading: boolean;
    handleGetOtp: any;
    consent: boolean;
    handleChange: any;
    customerName:any;
    applicationNumber:any
}

const MobileInputPage: React.FC<Props> = ({
    mobileNumber,
    setMobileNumber,
    mobileValidation,
    setMobileValidation,
    sendOtploading,
    handleGetOtp,
    consent,
    handleChange,
    customerName,
    applicationNumber,
}) => {
    const classes = useStyles();

    const verifyMobile = () => {
        if (
            parseInt(mobileNumber && mobileNumber[0]) < 6 || String(mobileNumber).length < 10) {
            setMobileValidation(false)
        }
    };
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <Grid
                    container
                    style={{ backgroundColor: "white" }}
                    className={classes.grid}
                    alignItems="center"
                    spacing={3}
                >
                        <Grid item container alignItems="center" direction="column">
                            <Grid item>
                                <Avatar className={classes.avatar} sx={{ background: "#002984" }}>
                                    <MobileFriendlyIcon />
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography component="h1" variant="h5">
                                    Mobile Authentication
                                </Typography>
                            </Grid>
                        </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        direction="column"
                    >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                            <form onSubmit={handleGetOtp}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    multiline
                                    type="text"
                                    label="Mobile Number *"
                                    fullWidth
                                    value={mobileNumber || ""}
                                    onChange={(e: any) => setMobileNumber(e.target.value)}
                                    onWheel={(e: any) => e.target.blur()}
                                    inputProps={{ maxLength: 10 }}
                                    onBlur={() => verifyMobile()}
                                    InputLabelProps={{
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                      }}
                                    disabled={applicationNumber===""||applicationNumber===null}
                                    onFocus={() => {
                                        setMobileValidation(true)
                                    }}
                                    helperText={
                                        !mobileValidation &&
                                        "Incorrect Phone Number"
                                    }
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">
                                            <Tooltip title="Ten digit mobile number to be authenticated" placement="top-end">
                                                <InfoIcon />
                                            </Tooltip>
                                        </InputAdornment>,
                                    }}
                                />
                                {/* <Tooltip title="Consent is required to make the API request." placement="top-end">
                                    <FormControlLabel
                                        control={
                                            <Switch checked={consent} onChange={handleChange} name="consent" />
                                        }
                                        label="Consent"

                                    />
                                </Tooltip> */}

                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignItems="center"
                                    direction="column"
                                    style={{marginTop:"1rem"}}
                                >
                                    <Grid item spacing={3} >
                                        <Stack direction="row" spacing={2}>
                                            <Button
                                                variant="contained"
                                                endIcon={<SendIcon />}
                                                type="submit"
                                                style={{background:"#002984"}}
                                                disabled={sendOtploading||applicationNumber===""||applicationNumber===null || !mobileValidation || String(mobileNumber).length < 10}
                                            >
                                                Get OTP
                                            </Button>
                                            <Button variant="contained" endIcon={<RestartAltIcon />}  style={{background:"#790e8b"}} onClick={() => setMobileNumber("")}>
                                                Reset Request
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>

                </Grid>

            </div>
        </Container>
    )
}
const mapStateToProps = (state: any) => ({

})
export default connect(mapStateToProps, {

})(MobileInputPage)