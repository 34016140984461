import React from "react";
import loader from "./loader1.svg";
function Loader() {
  return (
    <div className="loader-wrapper" 
    style={{
      // display:"trans",
      position:"fixed",
      zIndex:1,
      paddingTop:"100px",
      left:500,
      top:200,
      overflow:"auto",
      opacity:"0.5",
      backgroundColor:"transparent"

    }}>
      <img src={loader} alt="Loading" />
    </div>
  );
}

export default Loader;
