import React from "react";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled} from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import Button from "@mui/material/Button";
import { connect, useDispatch } from "react-redux";
import "./index.sass";
import { makeStyles } from '@mui/styles';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import { createTheme} from "@mui/material";
import { Avatar, Grid,TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { ACTIONS } from '../../interfaces/adminType'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
const theme = createTheme();
const useStyles = makeStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            textTransform: "capitalize",
        },
        labelText: {
            border: "1px solid",
            color: "red",
    
            '& label': {
                fontSize: "18px !important",
            }
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: "#2196f3"
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        submit: {
            margin: theme.spacing(3, 0, 2)
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
            height: "100%",
            // display: "flex",
            top: "5%",
            flexDirection: "column",
            justifyContent: "space-around",
        },
    })

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

interface Props {
    policyNumber: any;
    setPolicyNumber: any;
    customerName: any;
    setCustomerName: any;
    email:any;
    setEmail:any;
    PostEmailAuthentication:any;
}

const BookingsTableOffline: React.FC<Props> = ({
    policyNumber,
    setPolicyNumber,
    customerName,
    setCustomerName,
    email,
    setEmail,
    PostEmailAuthentication

}) => {
    const { register, reset, handleSubmit, watch, formState: { errors } } = useForm();
    const classes = useStyles();
    const dispatch = useDispatch()

    const handleEmailSubmit = async(e:any) =>{
        e.preventDefault()
        const body={
            email:email,
            firstName: "",
            lastName: "",
            ipAddress: "",
            consent: "Y",
            clientData: {
                caseId: ""
            }
        }
       await PostEmailAuthentication(body)
    }

    return (
        <div style={{ width: "100%" }} className="data-table">
            <form onSubmit={handleEmailSubmit}>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-end"
                    spacing={2}
                    style={{ margin: "2rem auto", background: "#fff", boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}
                >
                    <Grid item container alignItems="center" direction="column">
                        <Grid item>
                            <Avatar className={classes.avatar} sx={{ background: "#002984" }}>
                                <MobileFriendlyIcon />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography component="h1" variant="h5">
                                Email Authentication
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <TextField
                            className={classes.labelText}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={policyNumber}
                            onChange={(e: any) => setPolicyNumber(e.target.value)}
                            InputLabelProps={{
                               shrink:true
                              }}
                            id="policyNumber"
                            label="Application Number"
                            autoComplete="fullname"
                            autoFocus
                            required
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <TextField
                            className={classes.labelText}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="customerName"
                            label="Customer Name"
                            autoComplete="customerName"
                            autoFocus
                            value={customerName}
                            onChange={(e: any) => setCustomerName(e.target.value)}
                            InputLabelProps={{
                               shrink:true
                              }}
                            required
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <TextField
                            className={classes.labelText}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                            InputLabelProps={{
                               shrink:true
                              }}
                            required
                        />
                    </Grid>
                  

                    <Grid item md={6} sm={6} xs={12}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            style={{ marginBottom: "0.7rem",background:"#07639d"}}
                            // disabled={loading}
                            className={classes.submit}
                        >
                            Verify Email
                        </Button>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            style={{ marginBottom: "0.7rem",background:"#616161"}}
                            endIcon={<RestartAltIcon />}
                            onClick={()=>window.location.reload()}
                        >
                            Reset All/New Request
                        </Button>
                    </Grid>


                </Grid>
            </form>
         
        </div >
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {

})(BookingsTableOffline);
