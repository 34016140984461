import * as React from 'react';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Button, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputLabel, List, ListItemButton, ListItemText, ListSubheader, MenuItem, Select, Stack, Switch, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import Webcam from "react-webcam";
import "./index.sass";
import { validateFaceMatching, validateOcrKyc } from '../../action/branchServiceAction'
import { useCallback, useEffect, useRef, useState } from 'react';
import { Fingerprint } from '@mui/icons-material';
import Loader from '../../../components/loader';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MobileAuthPage from '../MobileValidation/index'
import EmailValidationPage from '../EmailValidation/emailValidationForm'
import BankValidationPage from '../BankValidation/index'
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    labelText: {
        border: "1px solid",
        color: "red",

        '& label': {
            fontSize: "18px !important",
        }
    },
    content: {
        width: "100%",
        flexGrow: 21,
    },
    container: {
        width: "70%",
        flexGrow: 21,
    },

})
const videoConstraints = {
    width: 200,
    height: 200,
    facingMode: "user"
};
interface Props {
    validateFaceMatching: any
    faceMatchLoading: any;
    faceMatchingResults: any;
    validateOcrKyc: any;
    ocrKycLoading: any;
    ocrKycdata: any
}

const EmailValidationForm: React.FC<Props> = ({
    validateFaceMatching,
    faceMatchLoading,
    faceMatchingResults,
    validateOcrKyc,
    ocrKycLoading,
    ocrKycdata,

}) => {
    const classes = useStyles();
    // const theme = createTheme();
    // let navigate = useNavigate();
    const [policyNumber, setPolicyNumber] = React.useState("")
    const [otp, setOtp] = useState<any>("")
    const [visitPurpose, setVisitPurpose] = useState<any>("")
    const [policyNumberError, setPolicyNumberError] = useState<any>("")
    const [picture, setPicture] = useState('')
    const [ocrPicture, setOcrPicture] = useState('')
    const [openWebCam, setOpenWebCam] = useState<boolean>(false)
    const [openOcrWebCam, setOpenOcrWebCam] = useState<boolean>(false)
    const [servicePage, setServicePage] = useState<any>("")
    const [state, setState] = React.useState({
        maskAadhaar: false,
        docType: false,
        hideAadhaar: false,
        conf: false,
    });
    //WebCam image capture and image reference
    const webcamRef = useRef<Webcam>(null);
    const webcam2Ref = useRef<Webcam>(null);

    const capture = useCallback(() => {
        const pictureSrc = webcamRef?.current?.getScreenshot()
        setPicture(pictureSrc ? pictureSrc : "")
    }, []);

    const captureOcrPic = useCallback(() => {
        const ocrPictureSrc = webcam2Ref?.current?.getScreenshot()
        setOcrPicture(ocrPictureSrc ? ocrPictureSrc : "")
    }, []);

    //Base64 to formdata converter
    const DataURIToBlob=(dataURI: string)=>{
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
      }

    //Visit Purpose dropdown change
    const handleChange = (event: any) => {
        setVisitPurpose(event.target.value);
    };
    //Cocument Type switch change
    const handleDocTypeChange = (event: any) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };
    //WebCam Open function
    const handleOpenWebCam = () => {
        if (policyNumber !== "") {
            if (policyNumber.length !== 8) {
                setPolicyNumberError("Please provide valid 8 digit policy number")
            }
            else {
                setOpenWebCam(!openWebCam)
                // localStorage.setItem('policynumber', policyNumber);
                setPolicyNumberError("")
            }
        }
        else {
            setPolicyNumberError("please provide policy number.....")
        }
    }
    //Validation and Api Call
    const validateFaceMatch = async (e: any) => {
        e.preventDefault()
        //DataURIToBlob
        // const formData = new FormData();
        // const img1=DataURIToBlob(picture)
        // const img2=DataURIToBlob(ocrPicture)
        // formData.append('image1', img1, 'image.jpg') 
        // formData.append('image2', img2, 'image.jpg') 
        // const pic1=DataURIToBlob(picture)
        // const pic2=DataURIToBlob(ocrPicture)
        // const formData = new FormData();
        
        const body = {
            image1B64:picture,
            image2B64:ocrPicture
        }
        // // validateFaceMatching(formData)
        await validateFaceMatching(body)
    }
    const validateIdCard = async (e: any) => {
        
        e.preventDefault()
        const data = {
            fileB64: ocrPicture,
            maskAadhaar: state.maskAadhaar,
            hideAadhaar: state.hideAadhaar,
            conf: state.conf,
            docType: state.conf ? "d1" : ""
        }
        await validateOcrKyc(data)

    }

    //Localstorage call to store ocr name value
    useEffect(() => {
        if (ocrKycdata && ocrKycdata?.result && ocrKycdata?.result[0]?.details?.name) {
            localStorage.setItem('ocrname', ocrKycdata?.result[0]?.details?.name);
        }

    }, [ocrKycdata])
    return (
        <>
            {servicePage === "" ? (
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {/*Policy Number container*/}
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                            <TextField
                                className={classes.labelText}
                                required
                                onInput={(e: any) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value))
                                        .toString()
                                        .slice(0, 8);
                                }}
                                onWheel={(e: any) => e.target.blur()}
                                fullWidth
                                type="number"
                                id="policyNumber"
                                label="Enter Policy Number"
                                name="policyNumber"
                                autoComplete="policyNumber"
                                value={policyNumber}
                                disabled={openWebCam}
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                                onChange={(e: any) => {
                                    setPolicyNumber(e.target.value)
                                    if (policyNumber.length === 8) {
                                        setPolicyNumberError("")
                                    }
                                }}

                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <Button variant="contained" onClick={handleOpenWebCam}>{!openWebCam ? "Open WebCam" : "Close Webcam"}</Button>
                        </Grid>
                    </Grid>
                    <p style={{ color: "red" }}>{policyNumberError}</p>
                    {/*Webcame container*/}
                    {openWebCam ? (
                        <Grid container spacing={2} style={{ margin: "1px", marginTop: "1rem" }}>
                            <Grid item xs={6} sm={6} md={6} style={{ border: "1px solid black" }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Grid container spacing={2}>
                                                <Typography>Webcam Photo</Typography>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    {picture == '' ? (
                                                        <Webcam
                                                            audio={false}
                                                            height={200}
                                                            ref={webcamRef}
                                                            width={200}
                                                            mirrored={true}
                                                            screenshotFormat="image/jpeg"
                                                            videoConstraints={videoConstraints}
                                                        />
                                                    ) : (
                                                        <img src={picture} />
                                                    )}
                                                </Grid>
                                                <Grid xs={12} sm={12} md={12} >
                                                    {picture != '' ? (
                                                        <Stack direction="row" spacing={2} style={{ margin: "2px" }}>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setPicture('')
                                                                }}
                                                                className="btn btn-primary"
                                                            >
                                                                Retake
                                                            </button>
                                                            <button
                                                                onClick={(e) => {
                                                                    setOpenOcrWebCam(!openOcrWebCam)
                                                                }}
                                                                className="btn btn-primary"
                                                            >
                                                                Verify Id Card
                                                            </button>
                                                        </Stack>
                                                    ) : (
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                capture()
                                                            }}
                                                            className="btn btn-danger"
                                                        >
                                                            Capture
                                                        </button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Grid container spacing={2}>
                                                    {faceMatchingResults && faceMatchingResults?.statusCode === 101 ? (
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <List
                                                                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                                                component="nav"
                                                                aria-labelledby="nested-list-subheader"
                                                                subheader={
                                                                    <ListSubheader component="div" id="nested-list-subheader">
                                                                        Face Match Results
                                                                    </ListSubheader>
                                                                }
                                                            >
                                                                <ListItemButton>
                                                                    <ListItemText primary="Face Match : " />
                                                                    <ListItemText primary={faceMatchingResults?.faceMatchingResults?.match} />
                                                                </ListItemButton>
                                                                <ListItemButton>
                                                                    <ListItemText primary="Face Match Score : " />
                                                                    <ListItemText primary={faceMatchingResults?.faceMatchingResults?.matchScore} />
                                                                </ListItemButton>
                                                                <ListItemButton>
                                                                    <ListItemText primary="Confidence : " />
                                                                    <ListItemText primary={faceMatchingResults?.faceMatchingResults?.confidence} />
                                                                </ListItemButton>
                                                                <ListItemButton>
                                                                    <ListItemText primary="Review required : " />
                                                                    <ListItemText primary={faceMatchingResults?.faceMatchingResults?.reviewNeeded} />
                                                                </ListItemButton>
                                                            </List>

                                                        </Grid>
                                                    ) : ""}
                                                    {ocrKycdata && ocrKycdata?.statusCode === 101 ? (
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <List
                                                                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                                                component="nav"
                                                                aria-labelledby="nested-list-subheader"
                                                                subheader={
                                                                    <ListSubheader component="div" id="nested-list-subheader">
                                                                        OCR Results
                                                                    </ListSubheader>
                                                                }
                                                            >
                                                                <ListItemButton>
                                                                    <ListItemText primary="Document Type : " />
                                                                    <ListItemText primary={ocrKycdata && ocrKycdata?.result[0]?.details?.type} />
                                                                </ListItemButton>
                                                                <ListItemButton>
                                                                    <ListItemText primary="Name : " />
                                                                    <ListItemText primary={ocrKycdata && ocrKycdata?.result[0]?.details?.name?.value} />
                                                                </ListItemButton>
                                                            </List>

                                                        </Grid>
                                                    ) : ""}
                                          

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {openOcrWebCam ? (
                                <Grid item xs={6} sm={6} md={6} style={{ border: "1px solid black" }}>
                                    <Typography>Please hold your id card in front of this camera</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    {ocrPicture == '' ? (
                                                        <Webcam
                                                            audio={false}
                                                            height={200}
                                                            ref={webcam2Ref}
                                                            width={200}
                                                            mirrored={false}
                                                            imageSmoothing={true}
                                                            screenshotFormat="image/jpeg"
                                                            videoConstraints={videoConstraints}
                                                        />
                                                    ) : (
                                                        <>
                                                            <img src={ocrPicture} />
                                                            {ocrKycdata && ocrKycdata?.statusCode === 102 ? (
                                                                <p style={{ color: "red" }}>Id card image is not clear!!!!</p>
                                                            ) : ""}

                                                        </>

                                                    )}
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    {/* <TextField
                                               className="input"
                                               required
                                               fullWidth
                                               type="number"
                                               id="otp"
                                               label="Enter OTP"
                                               name="Otp"
                                               autoComplete="otp"
                                               value={otp}
                                               onChange={(e: any) => setOtp(e.target.value)}

                                           /> */}
                                                    <FormControl component="fieldset" variant="standard">
                                                        <FormLabel component="legend">If Required</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch checked={state.maskAadhaar} onChange={handleDocTypeChange} name="maskAadhaar" />
                                                                }
                                                                label="If the document is an Aadhar card,is the masked Aadhaar image required"
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch checked={state.docType} onChange={handleDocTypeChange} name="docType" />
                                                                }
                                                                label="Is the document a Driving Licence"
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch checked={state.hideAadhaar} onChange={handleDocTypeChange} name="hideAadhaar" />
                                                                }
                                                                label="If the document is an Aadhaar card, is the masked Aadhaar number required?"
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch checked={state.conf} onChange={handleDocTypeChange} name="conf" />
                                                                }
                                                                label="Is the Confidence score required?"
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} >
                                            {ocrPicture != '' ? (
                                                <Stack direction="row" spacing={2} style={{ margin: "2px" }}>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setOcrPicture('')
                                                        }}
                                                        className="btn btn-primary"
                                                    >
                                                        Retake
                                                    </button>
                                                    <button
                                                        onClick={(e: any) => validateIdCard(e)}
                                                        className="btn btn-primary"
                                                    >
                                                        Validate Id
                                                    </button>
                                                    <button
                                                            onClick={(e: any) => validateFaceMatch(e)}
                                                            className="btn btn-primary"
                                                        >
                                                            Validate Face Match
                                                        </button>
                                                    {ocrKycdata && ocrKycdata?.statusCode === 101 ? (
                                                        <button
                                                            onClick={(e: any) => validateFaceMatch(e)}
                                                            className="btn btn-primary"
                                                        >
                                                            Validate Face Match
                                                        </button>
                                                    ) : ""}
                                                </Stack>
                                            ) : (
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        captureOcrPic()
                                                    }}
                                                    className="btn btn-danger"
                                                >
                                                    Capture
                                                </button>
                                            )}
                                            {ocrKycLoading ? <Loader /> : faceMatchLoading ? <Loader /> : ""}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : ""}

                        </Grid>
                    ) : ""}
                    {/*Purpose of visit container*/}
                    {ocrKycdata && ocrKycdata?.statusCode === 101 && faceMatchingResults && faceMatchingResults?.statusCode === 101 && (
                    <Grid container spacing={2} style={{ margin: "2px", marginTop: "1rem" }}>
                        <Grid xs={6} sm={6} md={6} style={{ border: "1px solid black", padding: "1rem" }} >
                            <Grid xs={12} sm={12} md={12} >
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Purpose of Visit...</InputLabel>
                                    <Select
                                        className='input'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={visitPurpose}
                                        label="Purpose of Visit..."
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"servie"}>Service inquiry</MenuItem>
                                        <MenuItem value={"claim"}>Claim inquiry</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} >
                                <Grid container >
                                    {visitPurpose !== "claim" && visitPurpose !== "" ? (
                                        <Grid xs={6} sm={6} md={6} >
                                            <Button color="secondary" onClick={() => setServicePage("mobile")}>
                                                Mobile Authentication
                                            </Button>
                                        </Grid>
                                    ) : ""}
                                    {visitPurpose !== "claim" && visitPurpose !== "" ? (
                                        <Grid xs={6} sm={6} md={6} >
                                            <Button  style={{background:"#07639d"}}onClick={() => setServicePage("email")}>
                                                Email Authentication{"  "}
                                            </Button>
                                        </Grid>
                                    ) : ""}
                                    {visitPurpose !== "" ? (
                                        <Grid xs={6} sm={6} md={6} >
                                            <Button color="secondary" onClick={() => setServicePage("bank")}>
                                                Bank Authentication
                                            </Button>
                                        </Grid>
                                    ) : ""}

                                    {visitPurpose !== "" ? (
                                        <Grid xs={6} sm={6} md={6} >
                                            <Button  style={{background:"#07639d"}}>
                                                Ekyc Authentication
                                            </Button>
                                        </Grid>
                                    ) : ""}

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={6} sm={6} md={6} style={{ border: "1px solid black", padding: "1rem" }} >
                            <ListItemButton>
                                <ListItemText primary="Policy Number : " />
                                <ListItemText primary={policyNumber} />
                            </ListItemButton>
                            <Grid xs={12} sm={12} md={12} >
                                <ListItemButton>
                                    <ListItemText primary="Name : " />
                                    <ListItemText primary={ocrKycdata && ocrKycdata?.result && ocrKycdata?.result[0]?.details?.name?.value} />
                                </ListItemButton>
                            </Grid>
                        </Grid>

                    </Grid >
                    )} 
                </main >
            ) : servicePage === "mobile" ? (
                <main className={classes.content}>
                     <div className={classes.toolbar} />
                    <Button onClick={()=>setServicePage("")} startIcon={<ArrowBackIcon />}>
                        Go back
                    </Button>
                    <MobileAuthPage  />
                </main>
            ) : servicePage === "email" ? (
                <main className={classes.content}>
                    <Button onClick={()=>setServicePage("")} startIcon={<ArrowBackIcon />}>
                        Go back
                    </Button>
                    <EmailValidationPage />
                </main>
            ) : (
                <main className={classes.content}>
                    <Button onClick={()=>setServicePage("")} startIcon={<ArrowBackIcon />}>
                        Go back
                    </Button>
                    <BankValidationPage />
                </main>
            )}
        </>
    )
}
const mapStateToProps = (state: any) => ({
    faceMatchingResults: state.BranchServiceReducer.faceMatchingResults,
    faceMatchLoading: state.BranchServiceReducer.faceMatchLoading,
    ocrKycdata: state.BranchServiceReducer.ocrKycdata,
    ocrKycLoading: state.BranchServiceReducer.ocrKycLoading,
})
export default connect(mapStateToProps, {
    validateFaceMatching,
    validateOcrKyc
})(EmailValidationForm)
