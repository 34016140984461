import React, { useState } from "react";
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";

import "./index.sass";
import LoginForm from "../../components/LoginForm";
import { login } from "../../actions/loginActions";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { loading } = useSelector((state: any) => state.loginReducer);
  function isObjEqual(a: any, b: any) {
    const x = JSON.stringify(a);
    const y = JSON.stringify(b);

    return x === y;
  }
  function iterate(obj:any) {
    for (var key in obj) {
        if (obj[key] !== null && typeof obj[key] === "object") {
            iterate(obj[key]);
        } else if (obj[key] !== null && typeof obj[key] === "string") {
            obj[key] = obj[key].toUpperCase();
        }
    }
}

  const handleForm = async (form: object) => {
    const username=form["username"].toUpperCase()
    const loginCread={username:username,password:form["password"]}
    try {
     await dispatch(login(loginCread))
     navigate('/dashboard')
    } catch (error) {
      return error;
    }
  };
  return (
    <div className="login-container">
      {/* <div className="mainHeading">
        <h1 style={{color:"#ffffff",marginLeft:"30px"}}> PRAMERICA LIFE INSURANCE LTD.</h1>
      </div> */}
      <div className="login-container__form">
        <LoginForm loading={loading} handleForm={handleForm} />
      </div>
    </div>
  );
}