import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';
import {TextField,Paper, Typography, Avatar, Button, CssBaseline, Container, Grid } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {createTheme } from '@mui/material/styles';
// import OtpInput from 'react-otp-input';
import InputAdornment from '@mui/material/InputAdornment';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "80%",
        flexGrow: 21,
    },
    grid: {
        backgroundColor: "grey",
        height: "50vh",
        textAlign: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        background: "#2196f3"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
})
interface Props {
    otp: any;
    setOtp: any;
    validateOtpInput: any;
    handleValidateOtp: any;
}

const ValidateOtpPage: React.FC<Props> = ({
    otp,
    setOtp,
    validateOtpInput,
    handleValidateOtp,
}) => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <Grid
                    container
                    style={{ backgroundColor: "white" }}
                    className={classes.grid}
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item container>
                        <Grid item container alignItems="center" direction="column">
                            <Grid item>
                                <Avatar className={classes.avatar}>
                                    <LockOutlinedIcon />
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography component="h1" variant="h5">
                                    Verification Code
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Paper elevation={0}>
                            <Typography variant="h6">
                                Please enter the verification code sent to your mobile
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        direction="column"
                    >
                        <Grid item spacing={3} >
                            <TextField
                                autoComplete="given-name"
                                name="otp"
                                type="password"
                                required
                                fullWidth
                                id="otp"
                                label="Please Enter OTP"
                                autoFocus
                                value={otp}
                                onChange={(e: any) => setOtp(e.target.value)}
                                InputLabelProps={{
                                    style: { color: '#0a0a0a',fontWeight:"bold"},
                                  }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">
                                        <Tooltip title="Please enter otp">
                                            <InfoIcon />
                                        </Tooltip>
                                    </InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    direction="column"
                >
                    <Grid item spacing={3} >
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleValidateOtp}
                        >
                            Verify
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
}
const mapStateToProps = (state: any) => ({

})
export default connect(mapStateToProps, {

})(ValidateOtpPage)