import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import logo from "../../components/logo/pramerica_life_logo1.jpg";
import { Box, Button, Checkbox, FormControlLabel, Grid, MenuItem, Paper, Select, TextareaAutosize, TextField, Typography } from '@mui/material';
import "./index.sass";
import ReactToPrint from 'react-to-print';
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "100%",
        flexGrow: 21,
    },
    container: {
        width: "100%",
        flexGrow: 21,
    },

})
interface Props {

}
const PolicyAmedmentFormB: React.FC<Props> = ({
}) => {
    const classes = useStyles();
    const [error, setError] = useState("");
    const [policyNumber, setPolicyNumber] = useState<any>("")
    const [date, setDate] = useState<any>("")
    const [bankNameChange, setBankNameChange] = useState<any>("")
    const [branch, setBranch] = useState<any>("")
    const [accountNumber, setAccountNumber] = useState<any>("")
    const [micrCode, setMicrCode] = useState<any>("")
    const [reasonForChange, setReasonForChange] = useState<any>("")
    const [paymentFrequency, setPaymentFrequency] = useState<any>("")
    const [status, setStatus] = useState<boolean>(false)
    // const [title,setTitle]=useState<any>("")
    // const [firstName,setFirstName]=useState<any>()
    // const [middleName,setMiddleName]=useState<any>()
    // const [lastName,setLastName]=useState<any>()
    // const [address,setAddress]=useState<any>()
    const [declarantDetails, setDeclarantDetails] = useState<any>({
        title: "",
        firstName: "",
        lastName: "",
        middleName: "",
        address: "",
        city: "",
        state: "",
        pinCode: "",
        date: "",
        place: "",
    })
    const componentRef = useRef<any>(null);
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);
    const [effectiveChangeCheck, setEffectiveChangeCheck] = useState<boolean>(false)
    const handleChange = (event: any) => {
        setPaymentFrequency(event.target.name);
    };
    const handleDeclarationChange = (event: any) => {
        setDeclarantDetails({
            ...declarantDetails,
            [event.target.name]: event.target.value,
        })
    }

    const reactToPrintTrigger = React.useCallback(() => {
        return <Button
            variant="contained"
            style={{background:"#07639d"}}
        >Print Details
        </Button>;
    }, []);
    const handleSubmit = (e: any) => {
        e.preventDefault()
        setStatus(true)
    }
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Paper elevation={3} style={{ maxWidth: "80%", display: "flex", justifyContent: "center" }}>
                <form onSubmit={handleSubmit}>
                    <div ref={componentRef}>
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            direction="row"
                            spacing={3}
                            style={{ margin: "1rem auto", maxWidth: "80%", display: "flex", justifyContent: "center" }}
                        >
                            <Grid item xs={6} md={6}>
                                <img
                                    className="invoiceImage"
                                    src={logo}
                                    style={{ fontFamily: "sans-serif", height: "30px" }}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Typography variant="h6" gutterBottom>
                                    POLICY AMENDMENT FORM B
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography variant="body2" gutterBottom>
                                    The Policy Servicing Department Pramerica Life Insurance Limited.
                                </Typography>
                            </Grid>
                            {/* <div
                                    className="logoContainer"
                                    style={{
                                        display: "flex",
                                        padding: "1rem",
                                    }}
                                >
                                    <div style={{ width: "50%" }}>
                                        <img
                                            className="invoiceImage"
                                            src={logo}
                                            style={{ fontFamily: "sans-serif", height: "30px",marginTop:"20px" }}
                                        />
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <span
                                            className="invoiceHeading"
                                            style={{
                                                float: "right",
                                                textAlign: "right",
                                                fontFamily: "sans-serif",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <strong style={{ fontFamily: "sans-serif" }}>
                                                Pramerica Life Insurance Limited
                                            </strong>
                                            <br />
                                            DLF Cyber City, Phase-III,
                                            <br />
                                            Gurugram-122002<br />
                                            Tel +91 124 4697000, Fax +91 124 4697200
                                        </span>
                                    </div>
                                </div> */}
                            <Grid item xs={6} md={6}>
                                <TextField
                                    className="input"
                                    name="policyNumber"
                                    type="text"
                                    label="Policy Number"
                                    value={policyNumber}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => setPolicyNumber(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    className="input"
                                    name="date"
                                    type="date"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value as string)}
                                    style={{ width: "100%", margin: "0" }}
                                    id="noTyping"
                                    inputProps={{
                                        min: `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                                            ? `0${new Date().getMonth() + 1}`
                                            : new Date().getMonth() + 1
                                            }-${new Date().getDate() < 10
                                                ? `0${new Date().getDate()}`
                                                : new Date().getDate()
                                            }`
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography style={{ backgroundColor: "#1a237e", color: "#ffffff" }} variant="body2" gutterBottom>
                                    Change in Premium Payment Frequency
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography variant="body2" gutterBottom>
                                    I wish to change my premium payment frequency to (Please tick as applicable:)
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={paymentFrequency === "monthly"} onChange={handleChange} name="monthly" />
                                        }
                                        label="Monthly "
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={paymentFrequency === "quartely"} onChange={handleChange} name="quartely" />
                                        }
                                        label="Quartely"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={paymentFrequency === "halfYearly"} onChange={handleChange} name="halfYearly" />
                                        }
                                        label="Half yearly"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={paymentFrequency === "annually"} onChange={handleChange} name="annually" />
                                        }
                                        label="Annually"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={effectiveChangeCheck} onChange={(e: any) => setEffectiveChangeCheck(!effectiveChangeCheck)} name="change" />
                                    }
                                    label="Changes would be effective from the next policy anniversary"
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography style={{ backgroundColor: "#1a237e", color: "#ffffff" }} variant="body2" gutterBottom>
                                    Updation of Bank Details
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    className="input"
                                    name="changeinbankname"
                                    type="text"
                                    label="Change in Bank Name"
                                    value={bankNameChange}
                                    variant="outlined"
                                   InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => setBankNameChange(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    className="input"
                                    name="branch"
                                    type="text"
                                    label="Branch"
                                    value={branch}
                                    variant="outlined"
                                   InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => setBranch(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    className="input"
                                    name="accountNumber"
                                    type="text"
                                    label="Change in Account Number"
                                    value={accountNumber}
                                    variant="outlined"
                                   InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => setAccountNumber(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    className="input"
                                    name="micrcode"
                                    type="text"
                                    label="Change in MICR Code"
                                    value={micrCode}
                                    variant="outlined"
                                   InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => setMicrCode(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    className="input"
                                    name="reasonforchange"
                                    type="text"
                                    label="Reason For Change"
                                    value={reasonForChange}
                                    variant="outlined"
                                   InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => setReasonForChange(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography variant="body2" gutterBottom><strong>Note:</strong> Please attach a cancelled cheque copy of new bank account along with this requent.</Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography style={{ backgroundColor: "#1a237e", color: "#ffffff" }} variant="body2" gutterBottom>
                                    Declaration & Authorisation
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <ul>
                                    <li>
                                        <Typography variant="body2" gutterBottom>
                                            I/we agree that no request shall be treated as valid and effective unless received by Pramerica Life insurance Limited (hereinafter referred to as "the Company") during the lifetime of the Life Insurance and is finally accepted by the Company. Receipt of this form by a Life Associate/Life Consultant will not constitute receipt/acknowledgement by the Company.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body2" gutterBottom>
                                            I/we understand that (I) the company may be unable to process this application if I/we fail to provide any further information requested by the Company and I/we fully understand the contents of this form and all information has been filled by me/us.
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={9} md={9}>
                                <Typography variant="body2" gutterBottom>
                                    Signature of the Life insured ______________________________
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <TextField
                                    className="input"
                                    name="date"
                                    type="date"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value as string)}
                                    style={{ width: "100%", margin: "0" }}
                                    id="noTyping"
                                    inputProps={{
                                        min: `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                                            ? `0${new Date().getMonth() + 1}`
                                            : new Date().getMonth() + 1
                                            }-${new Date().getDate() < 10
                                                ? `0${new Date().getDate()}`
                                                : new Date().getDate()
                                            }`
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography style={{ backgroundColor: "#1a237e", color: "#ffffff" }} variant="body2" gutterBottom>
                                    Name and Address of the Declarant
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <Select
                                    className="input"
                                    name="title"
                                    variant="outlined"
                                    label="title"
                                    displayEmpty
                                    value={declarantDetails.title}
                                    style={{ width: "100%", margin: "0", height: "40px" }}
                                    onChange={(e: any) => handleDeclarationChange(e)}
                                    required
                                >
                                    <MenuItem value="">
                                        <em>Title</em>
                                    </MenuItem>
                                    <MenuItem value="mr">Mr.</MenuItem>
                                    <MenuItem value="mrs">Mrs.</MenuItem>
                                    <MenuItem value="ms">Ms.</MenuItem>
                                    <MenuItem value="dr">Dr.</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <TextField
                                    className="input"
                                    name="firstName"
                                    type="text"
                                    label="First Name"
                                    value={declarantDetails.firstName}
                                    variant="outlined"
                                   InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => handleDeclarationChange(e)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <TextField
                                    className="input"
                                    name="middleName"
                                    type="text"
                                    label="Middle Name"
                                    value={declarantDetails.middleName}
                                    variant="outlined"
                                   InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => handleDeclarationChange(e)}
                                />
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <TextField
                                    className="input"
                                    name="lastName"
                                    type="text"
                                    label="Last Name"
                                    value={declarantDetails.lastName}
                                    variant="outlined"
                                   InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => handleDeclarationChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* <TextField
                                className="input"
                                name="address"
                                type="text"
                                label="Address"
                                value={declarantDetails.address}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ width: "100%", margin: "0" }}
                                helperText={error} // error message
                                error={!!error}
                                onChange={(e: any) => handleDeclarationChange(e)}
                                required
                            /> */}
                                Address:
                                <TextareaAutosize
                                    aria-label="empty textarea"
                                    placeholder="Please enter address here...."
                                    name="address"
                                    required
                                    style={{ width: "100%", margin: "0" }}
                                    value={declarantDetails.address}
                                    onChange={(e: any) => handleDeclarationChange(e)}
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField
                                    className="input"
                                    name="city"
                                    type="text"
                                    label="City"
                                    value={declarantDetails.city}
                                    variant="outlined"
                                   InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => handleDeclarationChange(e)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField
                                    className="input"
                                    name="state"
                                    type="text"
                                    label="State"
                                    value={declarantDetails.state}
                                    variant="outlined"
                                   InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => handleDeclarationChange(e)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField
                                    className="input"
                                    name="pinCode"
                                    type="text"
                                    label="Pin Code"
                                    value={declarantDetails.pinCode}
                                    variant="outlined"
                                   InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => handleDeclarationChange(e)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    className="input"
                                    name="date"
                                    type="date"
                                    value={declarantDetails.date}
                                    onChange={(e: any) => handleDeclarationChange(e)}
                                    style={{ width: "100%", margin: "0" }}
                                    id="noTyping"
                                    inputProps={{
                                        min: `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                                            ? `0${new Date().getMonth() + 1}`
                                            : new Date().getMonth() + 1
                                            }-${new Date().getDate() < 10
                                                ? `0${new Date().getDate()}`
                                                : new Date().getDate()
                                            }`
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    className="input"
                                    name="place"
                                    type="text"
                                    label="Place"
                                    value={declarantDetails.place}
                                    variant="outlined"
                                   InputLabelProps={{
                                        shrink: true,
                                        style: { color: '#0a0a0a',fontWeight:"bold"},
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    helperText={error} // error message
                                    error={!!error}
                                    onChange={(e: any) => handleDeclarationChange(e)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography variant="body2" gutterBottom>
                                    <strong>Note:</strong><br />
                                    Please submit the duly filled form at the nearest Company branch office or mail it to our communication address.For the details of the branch office location, www.pramericalife.in or call 1800 102 7070 (Toll-Free) you are requested to ask for the acknowledgement of this form the branch office and retain it for future reference.
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        direction="row"
                        spacing={3}
                        style={{ margin: "1rem auto", maxWidth: "80%", display: "flex", justifyContent: "center" }}
                    >
                        <Grid xs={4} sm={4} md={4}>
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                Validate Form
                            </Button>
                            </Grid>
                            <Grid xs={4} sm={4} md={4}>
                            {status ? (
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    trigger={reactToPrintTrigger}
                                />
                            ) : ""}
                        </Grid>
                            <Grid xs={4} sm={4} md={4}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={()=>window.location.reload()}
                            >
                                Reset Form
                            </Button>
                        </Grid>
                    </Grid>

                </form>


            </Paper>

        </main>
    )
}

const mapStateToProps = (state: any) => ({
})
export default connect(mapStateToProps, {
})(PolicyAmedmentFormB)