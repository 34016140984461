import React, { useEffect, useState, useRef } from "react";
import logo from "../Logo/pramerica.jpg";
import { connect } from "react-redux";
// import moment from "moment";
import ReactToPrint from "react-to-print";
import { makeStyles } from '@mui/styles';
// import {createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Grid } from "@mui/material";
// const theme = createTheme();
const useStyles = makeStyles({
    listMainContainer: {
        fontFamily: "sans-serif",
        fontSize: "16px",
        fontWeight: 600,
        padding: "0 0.2rem 0.2rem 0.2rem",
        display: "flex",
        width: "100%"
    },
    LeftList: {
        fontFamily: "sans-serif",
        width: "50%",
        display: "inline"
    },
    rightList: {
        fontFamily: "sans-serif",
        width: "50%",
        display: "inline"
    },
    spamRecordL1: {
        fontFamily: "sans-serif",
        width: "40%",
        display: "inline"
    },
    spamRecordL2: {
        fontFamily: "sans-serif",
        width: "40%",
        display: "inline"
    },
    spamRecordL3: {
        fontFamily: "sans-serif",
        width: "40%",
        display: "inline"
    }
})
interface PropsCR {
    EmailDetailsData: any;
    customerName: any;
    applicationNumber: any
}

const EmailPdfPage: React.FC<PropsCR> = ({
    EmailDetailsData,
    customerName,
    applicationNumber,
}) => {
    const classes = useStyles();
    const componentRef = useRef<any>(null);
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);
    const reactToPrintTrigger = React.useCallback(() => {
        return <Button variant="contained" style={{background:"#07639d"}}>Print Details</Button>;
    }, []);
    // const handleBack = () => {
    //     localStorage.removeItem("addonPrice");
    // history.goBack();
    // };

    return (
        <div
            style={{
                margin: "50px auto", boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"
            }}
        >
            <div
                style={{
                    width: "842px",
                    border: "1px solid grey",
                    display: "contents",
                    margin: 20,
                }}
            >
                <div
                    ref={componentRef}
                    style={{
                        margin: 20,
                    }}
                >
                    <div>
                        <meta charSet="UTF-8" style={{ fontFamily: "sans-serif" }} />
                        <meta
                            name="viewport"
                            // content="width=device-width, initial-scale=1.0"
                            style={{ fontFamily: "sans-serif" }}
                        />
                        <title style={{ fontFamily: "sans-serif" }}>Email details</title>
                        <div
                            className="invoiceContainer"
                            style={{
                                fontFamily: "sans-serif",
                                marginInline: "5vw",
                                marginTop: 0,
                            }}
                        >
                            <div id="divToPrint" style={{ fontFamily: "sans-serif" }}>

                                <div
                                    className="logoContainer"
                                    style={{
                                        display: "flex",
                                        padding: "1rem",
                                    }}
                                >
                                    <div style={{ width: "50%" }}>
                                        <img
                                            className="invoiceImage"
                                            src={logo}
                                            style={{ fontFamily: "sans-serif", height: "70px" }}
                                        /> | LIFE INSURANCE
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <span
                                            className="invoiceHeading"
                                            style={{
                                                float: "right",
                                                textAlign: "right",
                                                fontFamily: "sans-serif",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <strong style={{ fontFamily: "sans-serif" }}>
                                                Pramerica Life Insurance Limited
                                            </strong>
                                            <br />
                                            DLF Cyber City, Phase-III,
                                            <br />
                                            Gurugram-122002<br />
                                            Tel +91 124 4697000, Fax +91 124 4697200
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="invoiceGrid"
                                    style={{ marginTop: "-8px", display: "flex", backgroundColor: "#2969b3", textAlign: "center", justifyContent: "center" }}
                                >
                                    <h4 style={{ color: "#ffffff" }}>Email Id Verification Details</h4>
                                </div>
                                <div style={{ fontFamily: "sans-serif", marginTop: "1.5rem" }}>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Application Number
                                        </div>
                                        <div className={classes.rightList}>
                                            {applicationNumber}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Email Id 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailOwnerDetails?.email || "--"}
                                        </div>
                                    </div>

                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Name Matched
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailOwnerDetails?.nameMatch === null ? "Negative" : "Positive" || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Domain Name 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.domainDetails?.domainName || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Domain Company 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.domainDetails?.domainCompany || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Country 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.domainDetails?.country || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Type 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.domainDetails?.domainCategory || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Is Domain Corporate 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.domainDetails?.domainCategory || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Email Exists 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailAndDomainValidationDetails?.emailExists || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Domain Creation Date 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailAndDomainValidationDetails?.domainAge || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        First seen date of the email 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailAndDomainValidationDetails?.firstVerificationDate || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Last seen date 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailAndDomainValidationDetails?.lastVerificationDate || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Status of the email 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailAndDomainValidationDetails?.status || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Email Domain Exists 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailAndDomainValidationDetails?.domainExists || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Score for fraud risk
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailAndDomainRiskDetails?.score || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Descriptive score and score level
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailAndDomainValidationDetails?.riskBand || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Relevant information to understand fraud score
                                        </div>
                                        <div className={classes.rightList}>
                                         ....   {/* {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailAndDomainValidationDetails?.lastVerificationDate || "--"} */}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Fraud risk level of the domain
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailAndDomainValidationDetails?.fraudRisk || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Is domain from a flagged country?
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.emailAndDomainValidationDetails?.lastFlaggedOn || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Given IP address 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.ipDetails?.ipAddress || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Whether IP anonymous?
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.ipDetails?.ipAnonymousDetected || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Region as per IP address
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.ipLocationDetails?.ipRegion || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        City as per IP address 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.ipLocationDetails?.ipCity || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Latitude associated with IP address 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.ipLocationDetails?.ipLatitude || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Longitude associated with IP address 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.ipLocationDetails?.ipLongitude || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Postal code associated with IP address 
                                        </div>
                                        <div className={classes.rightList}>
                                            {EmailDetailsData&&EmailDetailsData?.result&&EmailDetailsData?.result[0]?.ipLocationDetails?.ipPostalCode || "--"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Grid container xs={12} style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                <Grid xs={4} sm={4} md={4}></Grid>
                <Grid xs={4} sm={4} md={4}></Grid>
                <Grid xs={4} sm={4} md={4}>
                    <ReactToPrint
                        content={reactToPrintContent}
                        trigger={reactToPrintTrigger}
                    /></Grid>
            </Grid>
        </div>
    );
};
const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
    //   getBookingById,
})(EmailPdfPage);
