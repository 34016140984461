import React, { useEffect, useState } from 'react'
import { SubmitHandler } from "react-hook-form";
import Typography from '@mui/material/Typography';
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SendIcon from '@mui/icons-material/Send';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import { PostBankAuthentication } from '../../action/branchServiceAction'
import { ACTIONS } from '../../interfaces/branchServiceType';
import PdfFile from './bankPdf'
import PolicyBasicHeade from '../../../HOC/policyBasicHeade';
const steps = ['Validate Bank', 'Bank Details'];
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "100%",
        flexGrow: 21,
    },
    paper: {
        padding: "20px 20px 20px 20px",
        marginTop: "20px",
        width: "100%",
    },
})
interface Props {
    PostBankAuthentication: any
    BankDetailsData: any;
    BankDetailsLoading: boolean;
}
interface IFormInputs {
    firstName: string
    lastName: string
}

const onSubmit: SubmitHandler<IFormInputs> = data => console.log(data);

const BankValidationForm: React.FC<Props> = ({
    PostBankAuthentication,
    BankDetailsData,
    BankDetailsLoading,

}) => {
    const classes = useStyles();
    let dispatch = useDispatch();
    const { status, message, type } = useSelector((state: any) => state.snackBarReducer);
    const [ifscCode, setIfscCode] = useState<any>("")
    const [accountNumber, setAccountNumber] = useState<any>("")
    const [accountHolderName, setAccountHolderName] = useState<any>("")
    const [nameMatchType, setNameMatchType] = useState<any>("")
    const [applicationNumber, setApplicationNumber] = React.useState<any>("")
    const [customerName, setCustomerName] = React.useState<any>("")
    const [stateCheck, setStateCheck] = React.useState({
        consent: true,
        combinedSolution: false,
    });
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // const handleStep = (step: number) => () => {
    //     setActiveStep(step);
    // };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {

        window.location.reload();
        // dispatch({ type: ACTIONS.POST_MOBILE_DETAILS_DATA, payload: [] });
        setActiveStep(0);
        setCompleted({});
    };
    const handleChange = (event: SelectChangeEvent) => {
        setNameMatchType(event.target.value);
    };

    const handleBackAuthentication = async (e: any) => {
        e.preventDefault();
        const body = {
            accountNumber: accountNumber,
            accountHolderName: accountHolderName,
            ifsc: ifscCode,
            consent: stateCheck.consent ? "Y" : "N",
            nameMatchType: nameMatchType,
            useCombinedSolution: stateCheck.combinedSolution ? "Y" : "N"
        }
        await PostBankAuthentication(body)
    }
    const handleResetForm = () => {
        setAccountHolderName("")
        setAccountNumber("")
        setIfscCode("")
        setNameMatchType("")
        dispatch({ type: ACTIONS.POST_BANK_AUTHENTICATION_DATA, payload: [] });
    }
    React.useEffect(() => {
        if (BankDetailsData && BankDetailsData?.status === "101") {
            handleComplete()
        }
    }, [BankDetailsData])
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Box sx={{ width: '100%' }}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" >
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                </Box>
            </Box>
            {Object.keys(completed).length === 0 || BankDetailsLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: "center",
                        justifyContent: "center",
                        '& > :not(style)': {
                            width: "80%",
                            height: "80%",
                        },
                    }}
                >
                    <Paper className={classes.paper} elevation={15}>
                        <h3 style={{ color: "red" }}>{message}</h3>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, background: "#002984", justifyContent: "center", display: "flex", alignItems: "center" }}>
                                <AccountBalanceIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Bank Authentication
                            </Typography>
                        </Box>
                        <PolicyBasicHeade applicationNumber={applicationNumber} setApplicationNumber={setApplicationNumber} setCustomerName={setCustomerName} customerName={customerName} />
                        <form onSubmit={handleBackAuthentication}>
                            <Grid container spacing={2} sx={{ mt: 3 }}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="ifsc"
                                        label="IFSC Code"
                                        name="ifsc"
                                        autoComplete="ifsc"
                                        value={ifscCode}
                                        onChange={(e: any) => setIfscCode(e.target.value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">
                                                <Tooltip title="IFSC Code of the home branch of the account." placement="top-end">
                                                    <InfoIcon />
                                                </Tooltip>
                                            </InputAdornment>,
                                        }}
                                        InputLabelProps={{
                                            style: { color: '#0a0a0a',fontWeight:"bold"},
                                          }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="accountNumber"
                                        label="Account Number"
                                        name="accountNumber"
                                        autoComplete="accountNumber"
                                        value={accountNumber}
                                        onChange={(e: any) => setAccountNumber(e.target.value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">
                                                <Tooltip title="Account number to be verified" placement="top-end">
                                                    <InfoIcon />
                                                </Tooltip>
                                            </InputAdornment>,
                                        }}
                                        InputLabelProps={{
                                            style: { color: '#0a0a0a',fontWeight:"bold"},
                                          }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        fullWidth
                                        id="accountHolderName"
                                        required
                                        label="Account Holder Name"
                                        name="accountHolderName"
                                        autoComplete="accountHolderName"
                                        value={accountHolderName}
                                        onChange={(e: any) => setAccountHolderName(e.target.value)}
                                        InputLabelProps={{
                                            style: { color: '#0a0a0a',fontWeight:"bold"},
                                          }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">
                                                <Tooltip title="Name of the account holder whose account is being verified" placement="top-end">
                                                    <InfoIcon />
                                                </Tooltip>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Tooltip title="Whether the account holder is an individual or an entity" placement="top-end">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} fullWidth>
                                            <InputLabel id="demo-simple-select-standard-label">Name Match Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={nameMatchType}
                                                required
                                                onChange={handleChange}
                                                label="Name Match Type"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={"Individual"}>Individual</MenuItem>
                                                <MenuItem value={"Entity"}>Entity</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Tooltip>
                                </Grid>
                                {/* <Grid item xs={12}>
               <FormControl component="fieldset" variant="standard">
                   <FormGroup>
                   <Tooltip title="To be passed when combined solution needs to be used (Nonpenny + pennydrop)" placement="top-end">
                       <FormControlLabel
                           control={
                               <Switch checked={stateCheck.combinedSolution} onChange={handleSwitchChange} name="combinedSolution" />
                           }
                           label="Combined Solution"
                       />
                       </Tooltip>
                       <Tooltip title="Consent is required to make the API request." placement="top-end">
                       <FormControlLabel
                           control={
                               <Switch checked={stateCheck.consent} onChange={handleSwitchChange} name="consent" />
                           }
                           label="Consent"
                       />
                       </Tooltip>
                   </FormGroup>
               </FormControl>
           </Grid> */}
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Button type="submit" variant="contained" endIcon={<SendIcon />} style={{ background: "#07639d" }} fullWidth>
                                        Send
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Button onClick={handleResetForm} variant="contained" style={{ background: "#790e8b" }} endIcon={<RestartAltIcon />} fullWidth>
                                        Reset Form
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <br />
                        {BankDetailsLoading ? (
                            <LinearProgress color="secondary" />
                        ) : ""}
                    </Paper>

                </Box>
            ) : BankDetailsData && BankDetailsData?.result && BankDetailsData?.result?.data ? (
                <PdfFile BankDetailsData={BankDetailsData} />
            ) : ""}

            {/* {BankDetailsData && BankDetailsData?.result && BankDetailsData?.result?.data ? (
                <PdfFile BankDetailsData={BankDetailsData} />
            ) : BankDetailsData?.response?.data?.error} */}

        </main>
    )
}
const mapStateToProps = (state: any) => ({
    BankDetailsData: state.BranchServiceReducer.BankDetailsData,
    BankDetailsLoading: state.BranchServiceReducer.BankDetailsLoading,
})
export default connect(mapStateToProps, {
    PostBankAuthentication
})(BankValidationForm)