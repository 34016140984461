import * as React from 'react';
import { useTheme, createTheme } from '@mui/material/styles';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import StepButton from '@mui/material/StepButton';
import { GetMobileOtp, validateOtpInput, PostMobileDetails } from '../../action/adminAction'
import ValidateOtpPage from './validateOtpPage'
import { ACTIONS } from '../../interfaces/adminType'
import MobileValidationInput from './mobileInputPage'
import LinearProgress from '@mui/material/LinearProgress';
import ViewDetailsPage from './viewDetails'
import { useEffect, useState } from 'react';
import PolicyBasicHeade from '../../../HOC/policyBasicHeade';
import { Grid } from '@mui/material';
import ValidationForm from './validationForm'
import MobileDetailsPage from './viewDetails'
import './index.sass'
const steps = ['Get Otp', 'Validate Otp', 'Get Mobile Details'];
const theme = createTheme();
const useStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        width: "100%",
        flexGrow: 21,
    },
})
interface Props {
    GetMobileOtp: any;
    sendOtploading: boolean;
    otpRequestData: any;
    validateOtpInput: any;
    validateReqData: any;
    otpValidateLoding: boolean;
    PostMobileDetails: any;
    MobileDetailsData: any;
    mobileDetailsLoading: any;
}
const MobilevalidationPage: React.FC<Props> = ({
    GetMobileOtp,
    sendOtploading,
    otpRequestData,
    validateOtpInput,
    validateReqData,
    otpValidateLoding,
    PostMobileDetails,
    MobileDetailsData,
    mobileDetailsLoading
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch()
    const [policyNumber, setPolicyNumber] = useState<string>("")
    const [customerName, setCustomerName] = useState<string>("")
    const [mobileNumber, setMobileNumber] = useState<string>("")
    const [otp,setOtp]=useState<any>("")

  
    return (
        <Grid container spacing={3} style={{marginTop:"auto"}}>
             <Grid item xs={6} style={{marginBottom:"auto"}}>
                <ValidationForm
                policyNumber={policyNumber}
                setPolicyNumber={setPolicyNumber}
                customerName={customerName}
                setCustomerName={setCustomerName}
                mobileNumber={mobileNumber}
                setMobileNumber={setMobileNumber}
                otp={otp}
                setOtp={setOtp}
                GetMobileOtp={GetMobileOtp}
                otpRequestData={otpRequestData}
                validateOtpInput={validateOtpInput}
                otpValidateLoding={otpValidateLoding}
                sendOtploading={sendOtploading}
                />
             </Grid>
             <Grid item xs={6} style={{marginBottom:"auto"}}>
                <MobileDetailsPage
                    validateReqData={validateReqData}
                    otpRequestData={otpRequestData}
                    PostMobileDetails={PostMobileDetails}
                    MobileDetailsData={MobileDetailsData}
                    mobileDetailsLoading={mobileDetailsLoading}
                    policyNumber={policyNumber}
                    mobileNumber={mobileNumber}
                    // handleComplete={handleComplete}
                    customerName={customerName}
                    // applicationNumber={applicationNumber}
                />
             </Grid>
        </Grid>
    );
};
const mapStateToProps = (state: any) => ({
    sendOtploading: state.AdminReducer.sendOtploading,
    otpRequestData: state.AdminReducer.otpRequestData,
    validateReqData: state.AdminReducer.validateReqData,
    otpValidateLoding: state.AdminReducer.otpValidateLoding,
    MobileDetailsData: state.AdminReducer.MobileDetailsData,
    mobileDetailsLoading: state.AdminReducer.mobileDetailsLoading,
});

export default connect(mapStateToProps, {
    GetMobileOtp,
    validateOtpInput,
    PostMobileDetails
})(MobilevalidationPage);