import React, {useRef } from "react";
import logo from "../Logo/pramerica.jpg";
import { connect } from "react-redux";
// import moment from "moment";
import ReactToPrint from "react-to-print";
import { makeStyles } from '@mui/styles';
// import {createTheme } from '@mui/material/styles';
// const theme = createTheme();
const useStyles = makeStyles({
    listMainContainer: {
        fontFamily: "sans-serif",
        fontSize: "16px",
        fontWeight: 600,
        padding: "0 0.2rem 0.2rem 0.2rem",
        display: "flex",
        width: "100%"
    },
    LeftList: {
        fontFamily: "sans-serif",
        width: "40%",
        display: "inline"
    },
    rightList: {
        fontFamily: "sans-serif",
        width: "60%",
        display: "inline"
    }
})
interface PropsCR {
    BankDetailsData:any
}

const CashRecipt: React.FC<PropsCR> = ({
    BankDetailsData
 }) => {
    const classes = useStyles();
    const componentRef = useRef<any>(null);
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);
    const reactToPrintTrigger = React.useCallback(() => {
        return <button>Print Receipt</button>;
    }, []);
    const handleBack = () => {
        // localStorage.removeItem("addonPrice");
        // history.goBack();
    };

    return (
        <div
            style={{
                margin: "50px auto",
            }}
        >
            <button
                style={{
                    width: "120px",
                    borderRadius: "5%",
                    backgroundColor: "#ef5350",
                    color: "white",
                    marginLeft:"10rem"
                }}
                onClick={handleBack}
            >
                {"<< Go Back"}
            </button>
            <ReactToPrint
                content={reactToPrintContent}
                trigger={reactToPrintTrigger}
            />
            <div
                style={{
                    width: "842px",
                    border: "1px solid grey",
                    display:"contents",
                    margin: 20,
                }}
            >
                <div
                    ref={componentRef}
                    style={{
                        margin: 20,
                    }}
                >
                    <div>
                        <meta charSet="UTF-8" style={{ fontFamily: "sans-serif" }} />
                        <meta
                            name="viewport"
                            // content="width=device-width, initial-scale=1.0"
                            style={{ fontFamily: "sans-serif" }}
                        />
                        <title style={{ fontFamily: "sans-serif" }}>Bank Receipt</title>
                        <div
                            className="invoiceContainer"
                            style={{
                                fontFamily: "sans-serif",
                                marginInline: "5vw",
                                marginTop: 0,
                            }}
                        >
                            <div id="divToPrint" style={{ fontFamily: "sans-serif" }}>

                                <div
                                    className="logoContainer"
                                    style={{
                                        display: "flex",
                                        padding: "1rem",
                                    }}
                                >
                                    <div style={{ width: "50%" }}>
                                        <img
                                            className="invoiceImage"
                                            src={logo}
                                            style={{ fontFamily: "sans-serif", height: "70px" }}
                                        /> | LIFE INSURANCE
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <span
                                            className="invoiceHeading"
                                            style={{
                                                float: "right",
                                                textAlign: "right",
                                                fontFamily: "sans-serif",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <strong style={{ fontFamily: "sans-serif" }}>
                                                Pramerica Life Insurance Limited
                                            </strong>
                                            <br />
                                            DLF Cyber City, Phase-III,
                                            <br />
                                            Gurugram-122002<br />
                                            Tel +91 124 4697000, Fax +91 124 4697200
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="invoiceGrid"
                                    style={{ marginTop: "-8px", display: "flex", backgroundColor: "#2969b3", textAlign: "center", justifyContent: "center" }}
                                >
                                    <h4 style={{ color: "#ffffff" }}>Bank Account Verification details</h4>
                                </div>
                                <div style={{ fontFamily: "sans-serif",marginTop:"1.5rem" }}>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Application Number
                                        </div>
                                        <div className={classes.rightList}>
                                            D2581700018
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Status As Per Source
                                        </div>
                                        <div className={classes.rightList}>
                                        {BankDetailsData?.result?.data?.source[0]?.statusAsPerSource}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Bank Transaction Status
                                        </div>
                                        <div className={classes.rightList}>
                                        {BankDetailsData?.result?.data?.source[0]?.data?.bankTxnStatus?"True":"False"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Account Number
                                        </div>
                                        <div className={classes.rightList}>
                                        {BankDetailsData?.result?.data?.source[0]?.data?.accountNumber}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        IFSC Code
                                        </div>
                                        <div className={classes.rightList}>
                                        {BankDetailsData?.result?.data?.source[0]?.data?.ifsc}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Account Holder Name
                                        </div>
                                        <div className={classes.rightList}>
                                        {BankDetailsData?.result?.data?.source[0]?.data?.accountName}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Name Verified
                                        </div>
                                        <div className={classes.rightList}>
                                        {BankDetailsData?.result?.comparisionData?.inputVsSource?.flags?.accountHolderName?.result?"Y":"N"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Bank Response
                                        </div>
                                        <div className={classes.rightList}>
                                        {BankDetailsData?.result?.data?.source[0]?.data?.bankResponse}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Bank RRN
                                        </div>
                                        <div className={classes.rightList}>
                                        {BankDetailsData?.result?.data?.source[0]?.data?.bankRRN}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Bank Status Code
                                        </div>
                                        <div className={classes.rightList}>
                                        {BankDetailsData?.result?.data?.source[0]?.data?.statusCode}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Valid
                                        </div>
                                        <div className={classes.rightList}>
                                        {BankDetailsData?.result?.data?.source[0]?.isValid?"True":"False"}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
    //   getBookingById,
})(CashRecipt);
