//Authentication
import React from "react";
import MainLayout from "../HOC/MainLayout";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import SnackBar from "../components/SnackBar";
import Login from "../containers/login";
import SecureStorage from "./SecureStorage";
import { checkUser } from "../actions/loginActions";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";


import Dash from "../containers/dash";

//Admin Dashboard
import AdminValidationDashboard from '../containers/Admin-agent'
import AdminHomePage from '../Admin/page/homepage'
import AdminBranchScreening from "../Admin/page/BranchScreening";
import AdminMobileValidation from '../Admin/page/MobileValidation'
import AdminEmailFormValidation from '../Admin/page/EmailValidation/index'
import AdminBankFromValidation from '../Admin/page/BankValidation'
import AdminPanValidation from '../Admin/page/PanCardValidation/index'
import AdminAmedmentForm from '../Admin/Form/PolicyAmedmentFormB'

//Validation Dashboard
import BSBranchScreening from "../BranchServiceDashboard/page/BranchScreening";
import BSValidationDashboard from '../containers/BranchService-agent'
import BSMobileValidation from '../BranchServiceDashboard/page/MobileValidation'
import BSEmailFormValidation from '../BranchServiceDashboard/page/EmailValidation/emailValidationForm'
import BSBankFromValidation from '../BranchServiceDashboard/page/BankValidation'
import BSPanValidation from '../BranchServiceDashboard/page/PanCardValidation/index'
import BSAmedmentForm from '../BranchServiceDashboard/Form/PolicyAmedmentFormB'

const token = SecureStorage.getItem("token");
function AuthenticatedRoutes(props) {
  if (window) {
    var path = window.location.pathname;
    var query = window.location.search;
  }
  if (token) {
    return (
      <Routes>
        <Route
          path="/"
          element={<Dash path={path} query={query} />}
        />
      </Routes>)
  }
  else{
    return <Route render={() => <Navigate to="/" />} />;
  }
}
const HasRole = ({ children, role }) => {

  if (!token) {
    console.log("waiting for token")
        window.location = "/";
  }

  const decoded = jwt_decode(token);
  console.log('check token')
  if (decoded.user_group !== role) {
    checkUser();
    window.location = "/dashboard";
  } else {
    return children;
  }
  
}
function AppRoutes(props) {

  return (
    <BrowserRouter>
     <MainLayout>
      <SnackBar />
      
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="dashboard"
          element={<AuthenticatedRoutes />}
        />
               <Route
          path="/dashboard/admin/*"
          element={
           
            <HasRole role="Admin">
              <AdminValidationDashboard>
                <Routes>
                  <Route
                    path="/"
                    element={<AdminHomePage />}
                  />
                  <Route
                    path="/branchscreening"
                    element={<AdminBranchScreening />}
                  />
                  <Route
                    path="mobile"
                    element={<AdminMobileValidation />}
                  />
                  <Route
                    path="email"
                    element={<AdminEmailFormValidation />}
                  />
                  <Route
                    path="bank"
                    element={<AdminBankFromValidation />}
                  />
                  <Route
                    path="pan-card"
                    element={<AdminPanValidation />}
                  />
                  <Route
                    path="form"
                    element={<AdminAmedmentForm />}
                  />

                </Routes>
              </AdminValidationDashboard>
            </HasRole>
          } />
        <Route
          path="/dashboard/branch/*"
          element={
           
            <HasRole role="Branch">
              <BSValidationDashboard>
                <Routes>
                  <Route
                    path="/"
                    element={<BSBranchScreening />}
                  />
                  <Route
                    path="mobile"
                    element={<BSMobileValidation />}
                  />
                  <Route
                    path="email"
                    element={<BSEmailFormValidation />}
                  />
                  <Route
                    path="bank"
                    element={<BSBankFromValidation />}
                  />
                  <Route
                    path="pan-card"
                    element={<BSPanValidation />}
                  />
                  <Route
                    path="form"
                    element={<BSAmedmentForm />}
                  />

                </Routes>
              </BSValidationDashboard>
            </HasRole>
          } />

      </Routes>
      </MainLayout>
    </BrowserRouter>
  );
}
export default connect(null, { checkUser })(AppRoutes);


