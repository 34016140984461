import { Box, createTheme, TextField } from '@mui/material'
import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux'
import "./index.sass";

const theme = createTheme();
const useStyles = makeStyles({
   
    labelText: {
        border: "1px solid",
        color: "red",

        '& label': {
            fontSize: "18px !important",
        }
    },
   
})

interface Props {
    customerName: any;
    setCustomerName: any;
    applicationNumber: any;
    setApplicationNumber: any
}
const BasicPolicyDetailsHeader: React.FC<Props> = ({
    customerName,
    setCustomerName,
    applicationNumber,
    setApplicationNumber

}) => {
    const classes = useStyles();
    const [policyNumberError, setPolicyNumberError] = useState<any>("")

    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1, width: '40ch'},
            }}
            style={{display:"flex",justifyContent:"center"}}
            noValidate
            autoComplete="off"
        >
            <TextField
                id="outlined-name"
                className={classes.labelText}
                fullWidth
                // onInput={(e: any) => {
                //     e.target.value = Math.max(0, parseInt(e.target.value))
                //         .toString()
                //         .slice(0, 11);
                // }}
                // onWheel={(e: any) => e.target.blur()}
                label="Application Number/Policy Number"
                value={applicationNumber}
                InputLabelProps={{
                    shrink:true
                  }}
                onChange={(e: any) =>{
                     setApplicationNumber(e.target.value)
                    //  if (applicationNumber.length === 8) {
                    //     setPolicyNumberError("")
                    // }
                }}
                helperText={policyNumberError}
            />
            <TextField
                id="outlined-name"
                className={classes.labelText}
                fullWidth
                label="Customer Name"
                value={customerName}
                InputLabelProps={{
                    shrink:true
                  }}
                onChange={(e: any) => setCustomerName(e.target.value)}
            />
        </Box>
    )
}

const mapStateToProps = (state: any) => ({
})
export default connect(mapStateToProps, {
})(BasicPolicyDetailsHeader)
