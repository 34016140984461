import React, { useEffect, useState, useRef } from "react";
import logo from "../../../components/logo/pramerica_life_logo1.jpg";
import { connect } from "react-redux";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { useTheme, createTheme } from '@mui/material/styles';
import { Grid, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
const theme = createTheme();
const useStyles = makeStyles({
    listMainContainer: {
        fontFamily: "sans-serif",
        fontSize: "12px",
        fontWeight: 600,
        padding: "0 0.2rem 0.2rem 0.2rem",
        display: "flex",
        width: "100%"
    },
    LeftList: {
        fontFamily: "sans-serif",
        width: "50%",
        display: "inline"
    },
    rightList: {
        fontFamily: "sans-serif",
        width: "50%",
        display: "inline"
    }
})
interface PropsCR {
    componentRef: any;
    picture: any;
    picture1: any;
    ocrPicture: any;
    ocrKycdata: any;
    faceMatchingResults: any;
}

const MobilePDFPage: React.FC<PropsCR> = ({
    componentRef,
    picture,
    picture1,
    ocrPicture,
    ocrKycdata,
    faceMatchingResults,
}) => {
    const classes = useStyles();
    const threeG: any = "3g_support"

    // const handleBack = () => {
    //     localStorage.removeItem("addonPrice");
    // history.goBack();
    // };

    return (
        <div
            style={{
                margin: "50px auto"
            }}
        >


            <div
                style={{
                    width: "842px", border: "1px solid grey", display: "contents", margin: 20,
                }}
            >
                <div ref={componentRef} style={{ margin: 20 }}>
           
                    <div
                        className="logoContainer"
                        style={{
                            display: "flex",
                            padding: "1rem",
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <img
                                className="invoiceImage"
                                src={logo}
                                style={{ fontFamily: "sans-serif", height: "30px", marginTop: "20px" }}
                            />
                        </div>
                        <div style={{ width: "50%" }}>
                            <span
                                className="invoiceHeading"
                                style={{
                                    float: "right",
                                    textAlign: "right",
                                    fontFamily: "sans-serif",
                                    fontSize: "14px",
                                }}
                            >
                                <strong style={{ fontFamily: "sans-serif" }}>
                                    Pramerica Life Insurance Limited
                                </strong>
                                <br />
                                DLF Cyber City, Phase-III,
                                <br />
                                Gurugram-122002<br />
                                Tel +91 124 4697000, Fax +91 124 4697200
                            </span>
                        </div>
                    </div>
                    <br />
                    <Grid container spacing={3}>
                        <Grid container spacing={2} style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset", marginLeft: "0.5rem" }}>
                            {(ocrKycdata && ocrKycdata?.statusCode) === 101 ? (
                                <Grid item xs={4} sm={4} md={4}>
                                    <h4>ID CARD IMAGE</h4>
                                    <img src={ocrPicture} alt={ocrPicture} />
                                </Grid>
                            ) : ""}
                            {(faceMatchingResults && faceMatchingResults?.statusCode) === 101 ? (
                                <Grid item xs={4} sm={4} md={4}>
                                    <h4>LIVE CUSTOMER PHOTO</h4>
                                    <img src={picture} alt={picture} />
                                </Grid>
                            ) : ""}
                            {(faceMatchingResults && faceMatchingResults?.statusCode) === 101 ? (
                                <Grid item xs={4} sm={4} md={4}>
                                    <h4>CUSTOMER ID CARD</h4>
                                    <img src={picture1} alt={picture1} />
                                </Grid>
                            ) : ""}
                            <Grid item xs={12} sm={12} md={12}>
                                {ocrKycdata && ocrKycdata?.statusCode === 101 ? (
                                    <div style={{ fontFamily: "sans-serif", marginLeft: "1rem" }}>
                                        <br />
                                        <h5>OCR RESULTS</h5>
                                        <div
                                            className={classes.listMainContainer}
                                        >
                                            <div className={classes.LeftList} >
                                                Document Type :
                                            </div>
                                            <div className={classes.rightList}>
                                                {ocrKycdata && ocrKycdata?.result[0]?.type}                                            </div>
                                        </div>
                                        <div
                                            className={classes.listMainContainer}
                                        >
                                            <div className={classes.LeftList} >
                                                Name :
                                            </div>
                                            <div className={classes.rightList}>
                                                {ocrKycdata && ocrKycdata?.result[0]?.details?.name?.value}                                            </div>
                                        </div>
                                        <div
                                            className={classes.listMainContainer}
                                        >
                                            <div className={classes.LeftList} >
                                                Father Name :
                                            </div>
                                            <div className={classes.rightList}>
                                                {ocrKycdata && ocrKycdata?.result[0]?.details?.father?.value}                                            </div>
                                        </div>
                                        {ocrKycdata && ocrKycdata?.result[0]?.type === "Pan" ? (
                                            <div>
                                                <div
                                                    className={classes.listMainContainer}
                                                >
                                                    <div className={classes.LeftList} >
                                                        Pan Number :
                                                    </div>
                                                    <div className={classes.rightList}>
                                                        {ocrKycdata && ocrKycdata?.result[0]?.details?.panNo?.value}                                            </div>
                                                </div>
                                                <div
                                                    className={classes.listMainContainer}
                                                >
                                                    <div className={classes.LeftList} >
                                                        DOB :
                                                    </div>
                                                    <div className={classes.rightList}>
                                                        {ocrKycdata && ocrKycdata?.result[0]?.details?.date?.value}                                            </div>
                                                </div>
                                            </div>
                                        ) : ocrKycdata && ocrKycdata?.result[0]?.type.includes("Aadhaar") ? (
                                            <div>
                                                <div
                                                    className={classes.listMainContainer}
                                                >
                                                    <div className={classes.LeftList} >
                                                        Aadhaar Number :
                                                    </div>
                                                    <div className={classes.rightList}>
                                                        {ocrKycdata && ocrKycdata?.result[0]?.details?.aadhaar?.value}                                            </div>
                                                </div>
                                                <div
                                                    className={classes.listMainContainer}
                                                >
                                                    <div className={classes.LeftList} >
                                                        Gender :
                                                    </div>
                                                    <div className={classes.rightList}>
                                                        {ocrKycdata && ocrKycdata?.result[0]?.details?.gender?.value}                                            </div>
                                                </div>
                                                <div
                                                    className={classes.listMainContainer}
                                                >
                                                    <div className={classes.LeftList} >
                                                        DOB :
                                                    </div>
                                                    <div className={classes.rightList}>
                                                        {ocrKycdata && ocrKycdata?.result[0]?.details?.dob?.value}                                            </div>
                                                </div>
                                            </div>
                                        ) : ""}
                                    </div>
                                ) : ""}
                                {faceMatchingResults && faceMatchingResults?.statusCode === 101 ? (
                                    <div style={{ fontFamily: "sans-serif", marginLeft: "1rem" }}>
                                        <br />
                                        <h5>FACE MATCH RESULTS</h5>
                                        <div
                                            className={classes.listMainContainer}
                                        >
                                            <div className={classes.LeftList} >
                                                Face Match :
                                            </div>
                                            <div className={classes.rightList}>
                                                {faceMatchingResults && faceMatchingResults?.result && faceMatchingResults?.result?.match}
                                            </div>
                                        </div>
                                        <div
                                            className={classes.listMainContainer}
                                        >
                                            <div className={classes.LeftList} >
                                                Match Score :
                                            </div>
                                            <div className={classes.rightList}>
                                                {faceMatchingResults && faceMatchingResults?.result && `${Math.round(faceMatchingResults?.result?.matchScore)}%`}
                                            </div>
                                        </div>
                                        <div
                                            className={classes.listMainContainer}
                                        >
                                            <div className={classes.LeftList} >
                                                Confidance
                                            </div>
                                            <div className={classes.rightList}>
                                                {faceMatchingResults && faceMatchingResults?.result && `${Math.round(faceMatchingResults?.result?.confidence)}%`}
                                            </div>
                                        </div>
                                        <div
                                            className={classes.listMainContainer}
                                        >
                                            <div className={classes.LeftList} >
                                                Review Needed :
                                            </div>
                                            <div className={classes.rightList}>
                                                {faceMatchingResults && faceMatchingResults?.result && faceMatchingResults?.result?.reviewNeeded}
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>

        </div >
    );
};
const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
    //   getBookingById,
})(MobilePDFPage);
