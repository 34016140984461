import React, { useEffect, useState, useRef } from "react";
import logo from "../../../components/logo/pramerica_life_logo1.jpg";
import { connect } from "react-redux";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { useTheme, createTheme } from '@mui/material/styles';
import { Grid } from "@mui/material";
const theme = createTheme();
const useStyles = makeStyles({
    listMainContainer: {
        fontFamily: "sans-serif",
        fontSize: "16px",
        fontWeight: 600,
        padding: "0 0.2rem 0.2rem 0.2rem",
        display: "flex",
        width: "100%"
    },
    LeftList: {
        fontFamily: "sans-serif",
        width: "40%",
        display: "inline"
    },
    rightList: {
        fontFamily: "sans-serif",
        width: "60%",
        display: "inline"
    }
})
interface PropsCR {
    MobileDetailsData: any
    mobileNumber: any;
    customerName:any;
    applicationNumber:any
}

const MobilePDFPage: React.FC<PropsCR> = ({
    MobileDetailsData,
    mobileNumber,
    customerName,
    applicationNumber,
}) => {
    const classes = useStyles();
    const threeG: any = "3g_support"
    const componentRef = useRef<any>(null);
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);
    const reactToPrintTrigger = React.useCallback(() => {
        return <Button
            variant="contained"
            style={{background:"#07639d"}}
        >Print Details
        </Button>;
    }, []);
    // const handleBack = () => {
    //     localStorage.removeItem("addonPrice");
        // history.goBack();
    // };

    return (
        <div
            style={{
                margin: "50px auto",boxShadow:" rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"
            }}
        >
           

            <div
                style={{
                    width: "842px",
                    border: "1px solid grey",
                    display: "contents",
                    margin: 20,
                }}
            >
                <div
                    ref={componentRef}
                    style={{
                        margin: 20,
                    }}
                >
                    <div>
                        <meta charSet="UTF-8" style={{ fontFamily: "sans-serif" }} />
                        <meta
                            name="viewport"
                            // content="width=device-width, initial-scale=1.0"
                            style={{ fontFamily: "sans-serif" }}
                        />
                        <title style={{ fontFamily: "sans-serif" }}>Mobile Details</title>
                        <div
                            className="invoiceContainer"
                            style={{
                                fontFamily: "sans-serif",
                                marginInline: "5vw",
                                marginTop: 0,
                            }}
                        >
                            <div id="divToPrint" style={{ fontFamily: "sans-serif" }}>

                                <div
                                    className="logoContainer"
                                    style={{
                                        display: "flex",
                                        padding: "1rem",
                                    }}
                                >
                                    <div style={{ width: "50%" }}>
                                        <img
                                            className="invoiceImage"
                                            src={logo}
                                            style={{ fontFamily: "sans-serif", height: "30px",marginTop:"20px" }}
                                        />
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <span
                                            className="invoiceHeading"
                                            style={{
                                                float: "right",
                                                textAlign: "right",
                                                fontFamily: "sans-serif",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <strong style={{ fontFamily: "sans-serif" }}>
                                                Pramerica Life Insurance Limited
                                            </strong>
                                            <br />
                                            DLF Cyber City, Phase-III,
                                            <br />
                                            Gurugram-122002<br />
                                            Tel +91 124 4697000, Fax +91 124 4697200
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="invoiceGrid"
                                    style={{ marginTop: "-8px", display: "flex", backgroundColor: "#2969b3", textAlign: "center", justifyContent: "center" }}
                                >
                                    <h4 style={{ color: "#ffffff" }}>Mobile Verification Details</h4>
                                </div>
                                <div style={{ fontFamily: "sans-serif", marginTop: "1.5rem" }}>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Mobile Number
                                        </div>
                                        <div className={classes.rightList}>
                                            {mobileNumber}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                        Application Number
                                        </div>
                                        <div className={classes.rightList}>
                                        {applicationNumber}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Name
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.identity?.name}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Gender
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.identity?.gender || "--"}                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Date of Birth
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.identity?.date_of_birth || "--"}                                            </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Address
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.contact?.address || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Alternate Contact
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.contact?.alt_contact || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Email Id
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.contact?.email_id || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Work Email
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.contact?.work_email || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            3G Support
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.device?.threeG || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Device Activation Date
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.contact?.device_activation_date || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            IMEI Number
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.device?.imei || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Model Name
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.device?.model || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Education
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.profile?.education || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Language
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.profile?.language || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Marital Status
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.profile?.marital_status || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Occupation
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.profile?.occupation || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Relationships
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.profile?.relationships || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Workplace
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.profile?.workplace || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Activation date
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.sim_details?.activation_date || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Last activity data
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.sim_details?.last_activity_date || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Otp Validated
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.sim_details?.otp_validated ? "YES" : "NO" || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Provider
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.sim_details?.provider || "--"}
                                        </div>
                                    </div>
                                    <div
                                        className={classes.listMainContainer}
                                    >
                                        <div className={classes.LeftList} >
                                            Type
                                        </div>
                                        <div className={classes.rightList}>
                                            {MobileDetailsData?.result?.sim_details?.type || "--"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Grid container xs={12} style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                <Grid xs={4} sm={4} md={4}></Grid>
                <Grid xs={4} sm={4} md={4}></Grid>
                <Grid xs={4} sm={4} md={4}>   <ReactToPrint
                    content={reactToPrintContent}
                    trigger={reactToPrintTrigger}
                /></Grid>
            </Grid>
        </div >
    );
};
const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
    //   getBookingById,
})(MobilePDFPage);
